app.component('passwordInput', {
    require: {
        ngModelCtrl: 'ngModel'
    },
    bindings: {
        ngModel: '=',
        tabIndex: '<',
        onChange: '&'
    },
    template: `<input ng-attr-type="{{ showPassword ? \'text\' : \'password\' }}" placeholder="password" ng-model="$ctrl.ngModel" class="input" tabindex="$ctrl.tabIndex"
                      ng-change="$ctrl.onChange({value: $ctrl.ngModel})" />
               <span class="show-password fa" ng-class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" ng-click="showPassword = !showPassword"></span>`,
    controller: ['$scope', function ($scope) {
        var that = this;
    }]
});