app.component('packageEdit', {
    bindings: {},
    templateUrl: './App/Packages/PackageEdit.html',
    controller: ['$scope', '$location', '$routeParams', 'sessions', 'packageService', 'configService', 'textFormatService', 'photoService', 'growl',
        function ($scope, $location, $routeParams, sessions, packageService, configService, textFormatService, photoService, growl) {
            var that = this;

            that.softLimit = that.config ? that.config.softLimit : 40;

            that.loadPackage = function (id) {
                that.loadingPackage = true;
                packageService.getPackage({
                    packageId: $routeParams.packageId,
                    onSuccess: pkg => {
                        that.package = pkg;
                        that.updateDelta();
                    },
                    onComplete: _ => {
                        that.loadingPackage = false;
                    }
                });
            }

            $scope.$on('packageUpdated', function () {
                that.loadPackage();
            });

            that.back = function () {
                $location.url('/packages');
            };

            that.photoCount = function () {
                return 0;
                return that.package && that.package.Photos
                    ? that.package.Photos.length - that.deletedPhotoCount()
                    : 0;
            }

            that.photoCountExceedsSoftLimit = function () {
                return that.config && that.config.softLimit && that.package && that.package.Photos
                    && that.photoCount() > that.config.softLimit;
            }

            that.deletedPhotoCount = function () {
                return that.package && that.package.Photos
                    ? that.package.Photos.filter(x => x.MarkedForDeletion === true).length
                    : 0;
            }

            that.tourCount = function () {
                return that.package && that.package.Tours
                    ? that.package.Tours.filter(x => x.markedForDeletion !== true).length
                    : 0;
            }

            that.tourCountExceedsLimit = function () {
                return that.package && that.package.Tours && that.config && that.config.tourLimit
                    && that.package.Tours.length > that.config.tourLimit;
            }

            that.tourDeletionCount = function () {
                return that.package && that.package.Tours
                    ? that.package.Tours.filter(x => x.markedForDeletion === true).length
                    : 0;
            }

            that.href = function (url) {  // TODO: breaks almost every valid https url
                if (url && url.toLowerCase().startsWith("http://")) {
                    return url;
                } else {
                    return "http://" + url;
                }
            }

            that.redirect = function () {
                $location.url('/packages?_dgt=' + new Date().getTime()); // TODO: all navigation to this page should look for server updates
            }

            that.packageShared = function () {
                return that.package.MediaStatus != 'Draft';
            }

            that.isDisabled = function () {
                return (that.package && that.package.MediaStatus == 'Draft')
                    || that.isPublishing
                    || that.isSaving
                    || that.isNotifying
                    || that.isDeleting
                    || that.isProcessingNotify
                    || that.isProcessingPackageDelete
                    || that.isProcessingContact
                    || that.package.IsRetryingPublishAfterError;
            }

            that.updateDelta = function () {
                packageService.packageDelta({
                    package: that.package,
                    onSuccess: delta => {
                        $scope.$apply(() => { that.delta = delta; });
                    }
                });
            };

            // send package
            that.toggleModalPropose = function () {
                that.updateDelta();
                that.isProposeVisible = !that.isProposeVisible;
            };

            that.isProposeVisible = false;
            that.isProcessingNotify = false;
            that.notifyMember = function () {
                that.isProcessingNotify = true;

                packageService.notify(
                    that.packageId,
                    !that.emailPackageOwners,
                    onSuccess => {
                        growl.success('Package Shared');
                        that.isProcessingNotify = false;
                        that.isProposeVisible = false;
                        that.session = sessions.updatePackage(onSuccess.data);
                        $location.url('/packages');
                    },
                    onError => {
                        that.isProcessingNotify = false;
                        sessions.reportHttpError(onError);
                    }
                );
            }

            //delete packages
            that.canDeletePackage = function () {
                return that.package && (that.package.MediaStatus === 'Draft' || that.session.isAgent);
            }

            that.showModalDelete = false;
            that.isProcessingPackageDelete = false;
            that.deletePackage = function () { // TODO: all Delete stuff from this module should be unused now
                var cleanResponse = that.deleteResponse ? textFormatService.sanatize(that.deleteResponse) : null;

                that.isDeleting = true;
                packageService.deletePackage({
                    packageId: that.packageId,
                    reason: cleanResponse,
                    sendNotifcation: that.sendPackageDeleteNotification,
                    onSuccess: _ => {
                        that.redirect();
                    },
                    onComplete: _ => {
                        that.isProcessingPackageDelete = false;
                        that.isDeleting = false;
                        that.showModalDelete = false;
                    },
                });
            }

            that.confirmDeletePackage = function () {
                that.openDeleteModal = true;
            }

            //publish package
            that.startPublish = function () {
                that.updateDelta();
                that.showModalPublish = true;
            }

            that.getPrePublishErrors = function () {
                var photoError = that.photoCountExceedsSoftLimit()
                    ? `There is a limit of ${that.config.softLimit} photos that can be published to Matrix.  Please reduce the number of photos and try again.  `
                    : null,
                    tourError = that.tourCountExceedsLimit()
                        ? `There is a limit of ${that.config.tourLimit} tours that can be published to Matrix.  Please reduce the number of tours and try again.`
                        : null,
                    linkError = that.package.Listing === null
                        ? 'This media package can not be published until it has been linked with a listing.'
                        : null;

                return photoError || tourError || linkError
                    ? (photoError ? photoError : '') + (tourError ? tourError : '') + (linkError ? linkError : '')
                    : null;
            }

            that.openLegalDisclaimer = function () {
                var errorMessage = that.getPrePublishErrors();

                if (errorMessage) {
                    sessions.reportError(errorMessage);
                } else {
                    that.updateDelta();
                    that.legalDisclaimerModal = true;
                }
            }

            that.publish = function () {
                if (that.package.MediaStatus == 'Draft') // TODO: error messages are all serverside so they can use consistent text
                    return;

                var errorMessage = that.getPrePublishErrors();
                if (errorMessage) {
                    sessions.reportError(errorMessage);
                    return;
                }

                that.legalDisclaimerModal = false;
                that.isPublishing = true;
                that.completePublish();
                //that.deletePhotos(that.completePublish);
            };

            that.cancelPublish = function () {
                that.isCancelingPublish = true;
                packageService.cancelPublish(
                    that.package.MediaPackageId,
                    function (response) {
                        that.isCancelingPublish = false;
                        growl.success('Cancel Publish Request Sent');
                        that.back();
                    }, function (response) {
                        that.isCancelingPublish = false;
                        sessions.reportHttpError(response);
                    }
                );
            }

            that.deletePhotos = function (onComplete) { // TODO: the server needs to do this
                var photosToDelete = that.package.Photos.filter(photo => photo.markedForDeletion === true),
                    photosAfter = that.package.Photos.filter(photo => photo.markedForDeletion !== true),
                    updatePackage = function () {
                        that.package.Photos = photosAfter;
                        that.package.Photos.PhotoCount = photosAfter.length;
                    },
                    toDeleteCount = photosToDelete.length,
                    success = function (response) {
                        toDeleteCount--;

                        if (toDeleteCount === 0) {
                            updatePackage();
                            onComplete();
                        }
                    },
                    error = function (response) {
                        that.isPublishing = false;
                        sessions.reportHttpError(response);

                    };

                photosToDelete.forEach(photo => {
                    photoService.deletePhoto(that.session, that.packageId, photo.MediaKey, success, error)
                });

                if (toDeleteCount === 0) {
                    onComplete();
                }
            }

            that.completePublish = function () {
                packageService.approve(
                    that.session,
                    that.package.MediaPackageId,
                    function (response) {
                        that.session = sessions.updatePackage(response.data);
                        that.showModalPublished = true;
                        that.isPublishing = false;
                    }, function (response) {
                        that.isPublishing = false;
                        sessions.reportHttpError(response);
                    }
                );
            }

            //send feedback
            that.sendFeedback = function () {
                that.notifyIsFeedback = true;
                that.notifyTitle = 'Send Feedback';
                that.notifyMessage = `Tell us what you think.`;;
                that.openNotifyModal = true;
            }

            //link package
            that.updatePackage = function (package) {
                that.package = package;
            }

            // v2
            that.showModalContact = false;
            that.contactMessage = '';
            that.isProcessingContact = false;
            that.contactCreator = function () {
                that.isProcessingContact = true;

                packageService.contactCreator(
                    sessions.getCurrent(),
                    that.package.MediaPackageId,
                    textFormatService.sanatize(that.contactMessage),
                    response => {
                        that.showModalContact = false;
                        that.isProcessingContact = false;
                    }, // onSuccess
                    sessions.reportHttpError // onError
                );
            };

            that.historyUrl = function () {
                return `${$location.url()}/history`;
            }

            that.retryExpirationTime = function (package) {
                var expirationDate = new Date(package.FirstPublishFail);
                expirationDate.setMinutes(expirationDate.getMinutes() + that.config.failureRetryMinutes);
                return expirationDate;
            }

            that.init = function () {
                that.session = sessions.getCurrent();
                that.packageId = parseInt($routeParams.packageId, 10);

                //Fetch most up to date package from server
                that.loadPackage(that.packageId);

                configService.getConfig(
                    function (response) { that.config = response.data; },
                    function (response) { console.log('Error retrieving config values'); }
                );

                that.isPublishing = false;
                that.isSaving = false;
                that.isDeleting = false;
                that.legalDisclaimerModal = false;

                // v2
                that.isReady = true; // block processing during async actions
                that.showModalNotified = false;
                that.showModalPublish = false;
                that.showModalPublished = false;

                that.emailPackageOwners = true;
                that.sendPackageDeleteNotification = false;

                that.rootPath = $location.protocol() + '://' + $location.host();
                if ($location.port()) {
                    that.rootPath += ':' + $location.port();
                }
            };

            that.init();
        }]
});