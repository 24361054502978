app.component('packageView', {
    bindings: {},
    templateUrl: './App/Packages/PackageView.html',
    controller: ['$scope', 'growl', 'sessions', 'packageService', '$location',
        function ($scope, growl, sessions, packageService, $location) {

        var that = this;
        that.packages = that.packages ?? [];
        that.vendorSignupLink = 'https://www.recolorado.com/pros/media-provider-sign-up';

        that.copySignupLink = function () {
            navigator.clipboard.writeText(that.vendorSignupLink);
            growl.success('Sign-up link copied to clipboard');
        };

        that.init = function () {
            that.resort(null, null, true);
            sessions.initDropdowns();
            that.packages = that.getMyPackages();
            that.session = sessions.getCurrent();
            sessions.verifyCurrentSession(); //make sure that current session is still valid
            that.isVendor = that.session.isVendor;
        }

        that.needsAction = function (package) {
            return package &&
                (package.MediaStatus == 'Draft'
                || (that.session.isAgent && package.MediaStatus == 'Ready'));
        };

        that.isShared = function (package) {
            return package && package.MediaStatus != 'Draft';
        };

        that.packageStatus = function (package, showLongStatus) {
            let status = showLongStatus ? package.MediaStatusLong : package.MediaStatus;
            if (package.MediaStatus == 'Ready' && that.session.isVendor)
                status = showLongStatus ? 'Shared with Agent' : 'Shared';
            if (package.IsRetryingPublishAfterError)
                status = showLongStatus ? "Retrying Publish..." : "Retrying";
            return status;
        }

        that.ExpirationInDays = function (expirationDate) {
            var time = (new Date(expirationDate)).getTime() - Date.now();
            var days = time / (1000 * 3600 * 24);
            return Math.round(days);
        };

        that.showExpiration = function (package) {
            return false; // (package.MediaStatus == 'Draft' || package.MediaStatus == 'Ready') && package.ExpirationTimestamp != null;
        }

        that.resort = function (field, label, setDefaults) {
            if (setDefaults) {
                that.sortLabel = 'Date';
                that.sortAsc = false;
                that.sortValue = '-ModificationTimestamp';
            }
            else {
                that.sortLabel = label;
                if (field === that.sortValue) {
                    that.sortAsc = false;
                    that.sortValue = '-' + field;
                } else {
                    that.sortAsc = true;
                    that.sortValue = field;
                }
            }
        };

        that.toggleSortDir = function () {
            that.sortAsc = that.sortValue.startsWith('-');
            that.sortValue = that.sortAsc ? that.sortValue.substr(1) : '-' + that.sortValue;
        };

        that.getMyPackages = function () {
            that.loadingPackages = true;
            packageService.getPackages({
                onSuccess: packages => {
                    that.packages = packages;
                    that.resort(that.sortValue, that.sortLabel, true);
                },
                onComplete: _ => {
                    that.loadingPackages = false
                }
            });
        };

        that.getMyPackagesOld = function () {
            var success = function (response) {
                if (response === null || response.data === null) {
                    that.packages = that.session.packages = [];
                    that.errorMessage = 'There was an internal error';
                    that.openErrorModal = true;
                } else {
                    that.packages = that.session.packages = response.data.value;
                    that.packages.forEach(p => {
                        p.statusLabel = "Resume";
                    });
                }
                sessions.update(that.session);
                that.loadingPackages = false;
                that.resort(that.sortValue, that.sortLabel, true);
            },
            error = function (response) {
                that.packages = that.session.packages = [];
                that.loadingPackages = false;
                sessions.reportHttpError(response);
            };

            that.loadingPackages = true;
            that.cancelGetListings = packageService.getPackages(success, error);
        }

        that.selectPackage = function (package) {
            if (package.IsUnavailable) {
                package.isHighlighted = true;
                setTimeout(() => {
                    $scope.$apply(() => { package.isHighlighted = false; });
                }, 3000);
            } else {
                $location.url(`/packages/${package.MediaPackageId}`);
            }
        }

        that.searchListings = function () {
            $location.url('/listings');
        }

        //send feedback
        that.sendFeedback = function () {
            that.notifyIsFeedback = true;
            that.notifyTitle = 'Send Feedback';
            that.notifyMessage = `Tell us what you think.`;;
            that.openNotifyModal = true;
        }

        //Listing Link Button
        that.linkPackage = function (packageId) {
            $location.url(`/packages/${packageId}/linklisting`)
        }

        that.init();
    }]
});