app.component('tourView', {
    bindings: {
        package: '=',
        session: '=',
        config: '='
    },
    templateUrl: './App/Tours/Tours.html',
    controller: ['tourService', 'sessions', 'growl',
        function (tourService, sessions, growl) {

        var that = this;
       
        that.tourCount = function () {
            return that.package && that.package.Tours ? that.package.Tours.length : 0;
        };

        that.exceedsMaxCount = function () {
            return that.package && that.package.Tours && that.config
                && that.package.Tours.length > that.config.tourLimit;
        }

        that.href = function (url) {
            if (url === undefined || url.toLowerCase().startsWith("http://")) {
                return url;
            } else {
                return "http://" + url;
            }
        }

        that.add = function () {
            var newTour = {
                "MediaURL": "",
                "Order": that.package.Tours.length
            };
            that.package.Tours.push(newTour);
            that.editTour(newTour)
        }

        that.editTour = function (tour) {
            that.editUrl = true;
            that.oldUrl = tour.MediaURL;
            that.selectedTour = tour;
            that.session = sessions.updatePackage(that.package);
        }

        that.cancelEdit = function (tour) {
            that.editUrl = false;
            that.selectedTour = null;

            if (tour.MediaKey == undefined) {
                that.package.Tours.pop();
            } else {
                tour.MediaURL = that.oldUrl;
            }
        }

        that.done = function (tour) {
            that.newUrl = false;
            that.editUrl = false;          
            if (tour.MediaURL) {
                that.updateTour(tour, (tour.MediaKey == undefined));
            } else {
                that.package.Tours.pop();
            }
        }

        that.updateTour = function(tour, isAdd) {
            var t = tour,
            success = function (response) {
                that.updateTourList(response.data);
                growl.success('Tour updated');
            },
            error = function (response) {
                t.isSaving = false;
                sessions.reportHttpError(response);
            };

            t.isSaving = true;

            if (isAdd) {
                tourService.post(that.session, that.package.MediaPackageId,
                    t.MediaURL, t.Order, success, error);
            } else {
                tourService.patch(that.session, t.MediaKey, that.package.MediaPackageId,
                    t.MediaURL, t.Order, success, error);
            }
        }

        that.updateTourList = function (updatedTour) {
            that.package.Tours.forEach((tour, index) => {
                if (tour.MediaKey == updatedTour.MediaKey || tour.MediaKey == undefined) {
                    that.package.Tours[index] = updatedTour;
                }
            });
            that.session = sessions.updatePackage(that.package);
        }

        that.confirmDeleted = function (item) {
            that.markedForDeletion = item;
            that.deleteModalIsOpen = true;
        }

        that.delete = function (item) {
            success = function (response) {
                var i = that.package.Tours.indexOf(item);
                if (i > -1) { that.package.Tours.splice(i, 1); }

                that.deleteProcessing = false;
                that.deleteModalIsOpen = false;
                that.session = sessions.updatePackage(that.package);
                growl.success('Tour deleted');
            },
            error = function (response) {
                that.deleteProcessing = false;
                that.deleteModalIsOpen = false;
                sessions.reportHttpError(response);
            };

            that.deleteProcessing = true;
            tourService.delete(that.session, item.MediaKey, that.package.MediaPackageId, success, error);
        }

        that.dragControlListeners = {
            accept: function (sourceItemHandleScope, destSortableScope) { return true; },
            itemMoved: function (event) { },
            orderChanged: function (event) {
                that.package.Tours.forEach((tour, index) => {
                    tour.Order = index;
                    that.updateTour(tour, false);
                });
            },
            clone: false,
            allowDuplicates: false
            };

        that.cleanUrl = function (url) {
            return (url || '').replace('https://', '').replace('http://', '');
        }
    }]
});