app.component('agentSearch', {
    bindings: {
        onAgentSelect: '&'
    },
    templateUrl: './App/Agents/AgentSearch.html',
    controller: ['sessions', 'httpWithCancel',
        function (sessions, httpWithCancel) {
            var that = this;

            that.init = function () {
                that.session = sessions.getCurrent();
                that.agentSearch = null;
                that.isSearchingForMember = false;
                that.cancelSearchFunc = null;
                that.agentSearchResults = null;
                that.selectedAgent = null;
            };

            that.searchForAgents = function () {
                if (that.agentSearch !== null && that.agentSearch !== '') {
                    var args = {
                        method: 'POST',
                        url: './api/MemberSearch',
                        headers: { 'Content-Type': 'text/plain' },
                        data: that.agentSearch
                    },
                    success = function (response) {
                        that.agentSearchResults = response.data;
                        that.cancelSearchFunc = null;
                        that.isSearchingForMember = false;
                    },
                    error = function (response) {
                        that.cancelSearchFunc = null;
                        that.isSearchingForMember = false;
                        sessions.reportHttpError(response);
                    };

                    if (that.cancelSearchFunc !== null) {
                        that.cancelSearchFunc();
                    }

                    that.isSearchingForMember = true;
                    that.cancelSearchFunc = httpWithCancel.http(args, success, error);
                } else {
                    that.cancelSearchFunc = null;
                    that.isSearchingForMember = false;
                    that.agentSearchResults = [];
                }
            }

            that.noAgentsFound = function () {
                return that.isSearchingForMember === false && that.agentSearchResults !== '' && that.agentSearchResults !== null && that.agentSearchResults.length === 0;
            }

            that.selectAgent = function (agent) {
                that.onAgentSelect({ 'agent': agent });
            }

            that.highlightsSearchHits = function (agent) {
                let agentInfo = `${agent.MemberFullName ?? agent.MemberMlsId} (${agent.MemberMlsId})`;
                const searchTerms = that.agentSearch.split(/[ ]+/),
                    regex = new RegExp(`(${searchTerms.join('|')})`, 'gi'),
                    highlightedResult = agentInfo.replace(regex, match => `<strong>${match}</strong>`);

                if (agent.OfficeMlsId)
                    agentInfo += `, <span class="nowrap">Office ID: ${agent.OfficeMlsId}</span>`;

                return highlightedResult;
            };

            // TODO: delete this if no hidden behavior here
            that.highlightsSearchHitsUNUSED = function (agent) {

                var s = `${agent.MemberFullName} (${agent.MemberMlsId}) - ${agent.OfficeMlsId}`,
                    words = new Set( // remove duplicates
                        that.agentSearch.split(/[ ]+/)
                            .sort(function (a, b) { // order largest words first
                                return b.length - a.length;
                            })),
                    matches = [],
                    getAllIndices = function (arr, val) {
                        var indexes = [],
                            aUpper = arr.toUpperCase(),
                            vUpper = val.toUpperCase();
                            i = -1,
                            j = 0;
                        while ((i = aUpper.indexOf(vUpper, j)) != -1) {
                            if (i > -1) {
                                indexes.push(i);
                                j = i + val.length;
                            }
                        }
                        return indexes;
                    },
                    indexFree = function (index, matches) {
                        var result = true;
                        matches.forEach(match => {
                            if (index === match["index"] || (index > match["index"] && index < match["index"] + match["word"].length)) {
                                result = false;
                            }
                        });
                        return result;
                    };

                words.forEach(word => {
                    var hits = getAllIndices(s, word);

                    if (Array.isArray(hits) && hits.length > 0) {
                        hits.forEach(index => {
                            if (indexFree(index, matches)) {
                                matches.push({ "index": index, "word": word });
                            }
                        });
                    }
                });

                //replace search hits in reverse order
                matches = matches.sort(function (a, b) {
                    return b["index"] - a["index"];
                });
                matches.forEach(match => {
                    var index = match["index"],
                        endStart = match["index"] + match["word"].length,
                        start = (index === 0) ? "" : s.substring(0, index),
                        middle = `<strong>${match["word"]}</strong>`,
                        end = (endStart > s.length - 1) ? "" : s.substring(endStart);
                    s = start + middle + end;
                });

                // this approach falls to pieces when words match with part of the <strong> tag
                //words.forEach(word => {
                //    var reString = `${word}`,
                //        re = new RegExp(reString, 'gi');
                //    s = s.replace(re, `<strong>${word}</strong>`);
                //});

                return s;
            }

            that.init();
        }]
});