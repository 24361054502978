app.component('agentListings', {
    bindings: {
        selectedAgent: '<',
        packages: '<',
        onSelectListing: '&',
        onSearchFinished: '&'
    },
    templateUrl: './App/Listings/AgentListings/AgentListings.html',
    controller: ['sessions', 'httpWithCancel',
        function (sessions, httpWithCancel) {
            var that = this;

            that.init = function () {
                that.session = sessions.getCurrent();
                that.cancelSearchFunc = null;
                that.mode = 'agent-search';
                that.agentSearchResults = null;
                that.selectedAgent = null;
                that.isSearchingForListings = false;
                that.listingSearchResults = null;
                that.confirmCreateIsOpen = false;
                that.confirmCreateMessage = false;
                that.confirmCreateIsProcessing = false;
            };

            that.searchForListings = function () {
                that.listingSearchResults = [];

                if (!that.selectedAgent)
                    return;
                
                that.isSearchingForListings = true;
                httpWithCancel.http(
                    {
                        method: 'GET',
                        url: `./api/Agent/${that.selectedAgent.MemberMlsId}/Listings`

                    },
                    res => {
                        const listings = res.data;
                        for (var i = 0; i < listings.length; ++i) {
                            const listing = listings[i],
                                pkgIndex = that.packages.findIndex(p => p.ListingId == listing.ListingId),
                                pkg = pkgIndex >= 0 ? that.packages[pkgIndex] : null;
                            listing.packageId = pkg?.MediaPackageId;
                            listing.packageStatus = pkg?.MediaStatus;
                        }

                        that.listingSearchResults = listings;
                        that.isSearchingForListings = false;
                        that.onSearchFinished({ count: that.listingSearchResults?.length });
                    },
                    err => {
                        that.isSearchingForListings = false;
                        that.listingSearchResults = null;
                        that.onSearchFinished({ count: 0 });
                        sessions.reportHttpError(err);
                    }
                );                
            }

            that.noListingsFound = function () {
                return that.listingSearchResults?.length === 0 && that.isSearchingForListings === false;
            }

            that.selectListing = function (listing) {
                that.onSelectListing({ 'listing': listing });
            }

            that.$onChanges = function (changes) {
                if (changes.selectedAgent)
                    that.searchForListings();
            };

            that.init();
        }]
});