app.controller('modalNotificationCtrl', ['$scope', '$rootScope', function ($scope, $rootScope) {

    $scope.modalIsOpen = false;
    $scope.header = 'Test Header';
    $scope.message = 'Test Message';
    $scope.typeClass = 'is-danger';

    $rootScope.$on('postModalNotification', function (event, data) {
        $scope.modalIsOpen = true;
        $scope.header = data.header;
        $scope.message = data.message;
        $scope.data = data;

        switch (data.type) {
            case 'Primary':
                $scope.typeClass = 'is-primary';
                break
            case 'Info':
                $scope.typeClass = 'is-info';
                break;
            case 'Success':
                $scope.typeClass = 'is-success';
                break;
            case 'Warning':
                $scope.typeClass = 'is-warning';
                break;
            case 'Error':
            default:
                $scope.typeClass = 'is-danger';
        }    
    });
}]);