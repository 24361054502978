var app = angular.module('ptuApp', ['ngRoute', 'ngFileUpload', 'angular-sortable-view', 'as.sortable', 'ngCookies', 'LocalStorageModule', 'ngSanitize', 'angular-growl', 'hl.sticky'])
    .filter('dateTime', function () {
        return function (input) {
            if (!input)
                return '';

            const date = new Date(input);
            const month = date.toLocaleString("en-US", { timeZone: "America/Denver", month: "long" });
            const day = date.toLocaleString("en-US", { timeZone: "America/Denver", day: "numeric" });
            let hour = date.toLocaleString("en-US", { timeZone: "America/Denver", hour: "2-digit", hour12: true });
            const minute = date.toLocaleString("en-US", { timeZone: "America/Denver", minute: "2-digit" });
            const amPm = hour.endsWith('AM') ? 'am' : 'pm';

            // Remove leading zero if any
            if (hour.startsWith('0')) {
                hour = hour.slice(1, -3);
            } else {
                hour = hour.slice(0, -3);
            }

            const formattedDate = `${month} ${day} at ${hour}:${minute}${amPm}`
            return formattedDate; //.replace(/,/, '').replace(/:/g, '').replace(' AM', 'am').replace(' PM', 'pm');
        };
    });

app.config(['$routeProvider', '$httpProvider', '$locationProvider', 'growlProvider', function ($routeProvider, $httpProvider, $locationProvider, growlProvider) {
    $routeProvider
    .when('/login', { template: '<login />' })
    .when('/listings', { template: '<listing-search />' })
    .when('/packages', { template: '<package-view />' })
    .when('/proposals', { template: '<package-view />' })
    .when('/package/create', { template: '<package-create />' })
    .when('/packages/:packageId', { template: '<package-edit />' })
    .when('/packages/:packageId/history', { template: '<package-history />' })
    .when('/packages/:packageId/linklisting', { template: '<listing-link />' })
    .when('/password-reset', { template: '<password-reset />' })
    .when('/password-reset/:requestId', { template:'<password-reset />' })
    .when('/passwordreset', { template: '<password-reset />' })
    .when('/passwordreset/:requestId', { template: '<password-reset />' })
    .when('/email-subscription', { template:'<email-subscription />' })
    .when('/email-subscription/link', { template: '<email-subscription />' })
    .when('/account-settings', { template: '<account-settings />' })
    .when('/publish-permissions', {template: '<publish-permissions />'})
    .otherwise({ redirectTo: '/login' });

    //add auth cookie to each $http request
    $httpProvider.defaults.withCredentials = true;

    //removes '!#' from routs
    $locationProvider.html5Mode(true);

    growlProvider.globalTimeToLive({ success: 3000, error: 10000, warning: 5000, info: 4000 });
    growlProvider.globalDisableCountDown(true);
    growlProvider.onlyUniqueMessages(true);
    growlProvider.globalDisableIcons(true);
    growlProvider.globalPosition('top-center'); //top-left,top-right,bottom-left,bottom-right,top-center,bottom-center
}]);

app.run(['$http', function ($http) {
    $http.defaults.headers.common['FromUI'] = true;
    //$http.defaults.headers.common['X-XSRF-TOKEN'] = $cookies.get('PtuSession');    //manually set auth cookie
}]);

app.directive('modalDialog', function () {
    return {
        scope: {
            show: '=',
            modalTitle: '@',
            modalClass: '@',
            close: '&',

            hideCancel: '=',
            cancelLabel: '@',
            onCancel: '&',

            hideConfirm: '=',
            confirmLabel: '@',
            confirmIcon: '@',
            onConfirm: '&',

            showDelete: '<',
            onDelete: '&',
            autoClose: '<',

            secondaryLabel: '@',
            secondaryIcon: '@',
            onSecondary: '&',
            secondaryKeepOpen: '=',
            secondaryShow: '&',

            isProcessing: '<'
        },
        restrict: 'E',
        replace: true,
        transclude: true,
        //transclude: { // TODO?: <div ng-transclude="body"></div>
        //    body: '?modalDialogBody'
        //},
        link: function (scope, element, attrs) {
            scope.autoIsProcessing = attrs.isProcessing === true;
            scope.hasDelete = attrs.onDelete;
            scope.hasSecondary = attrs.onSecondary && (!attrs.secondaryShow || secondaryShow());
            scope.autoCloseModal = attrs.autoClose;
            //scope.close = (callback) => {
            //    if (!keepModalOpen && scope.show)
            //        scope.show = keepModalOpen;
            //    scope.isProcessing = false;
            //};

            scope.process = function (func, closeModal, uncancellable) {
                return () => {
                    if (scope.autoIsProcessing)
                        scope.isProcessing = true;

                    if (uncancellable)
                        scope.cancelLabel = 'Close';

                    if (func && typeof func == 'function')
                        func();

                    if (closeModal) {
                        scope.show = null;
                        scope.isProcessing = false;
                    }

                    if (scope.autoIsProcessing)
                        scope.isProcessing = false;
                    //scope.$dismiss('cancel');
                }
            }
            
            scope.cancel = scope.process(scope.onCancel, true, false);
            scope.delete = scope.process(scope.onDelete, scope.autoCloseModal, true);
            scope.secondary = scope.process(scope.onSecondary, !scope.secondaryKeepOpen, false);
            scope.confirm = scope.process(scope.onConfirm, scope.autoCloseModal, true);
        },
        template: // TODO: ng-keydown="$event.keyCode === 13 && confirm()" // make 'Enter' feel good
            `<div class="modal global-modal" ng-class="{ 'is-active': show }" >
                <div class="modal-background"></div>
                <div class="modal-content">
                    <div class="message {{modalClass || 'DEFAULTis-secondary'}}">
                        <div class="message-header">
                            {{modalTitle}}
                        </div>
                        <div class="message-body">
                            <div ng-transclude></div>
                            <div class="button-footer is-right">
                                <div class="buttons is-pulled-left">
                                    <button class="button" ng-click="cancel()" ng-hide="hideCancel" ng-disabled="isProcessing">
                                       <span class="is-hidden-mobile has-text-danger">&times;&nbsp;</span>{{cancelLabel || 'Cancel'}}
                                    </button>
                                </div>
                                <div class="buttons is-pulled-right">
                                    <button class="button is-danger is-outlined" ng-click="delete()" ng-show="hasDelete">
                                        <i class="fa fa-trash"></i><span class="is-hidden-mobile">&nbsp;Delete</span>
                                    </button>
                                    <button class="button is-secondary" ng-show="hasSecondary" ng-click="secondary(this)">
                                        <i class="fa fa-{{secondaryIcon || 'check'}}" ng-show="secondaryIcon">&nbsp;</i>{{secondaryLabel || 'Alternate'}}
                                    </button>
                                    <button class="button {{modalClass || 'is-primary'}}" ng-click="confirm()" ng-class="{ 'is-loading': isProcessing }" ng-hide="hideConfirm">
                                        <i class="fa fa-{{confirmIcon || 'check'}}"></i>&nbsp;{{confirmLabel || 'Done'}}
                                    </button>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>`
    };
});


app.directive('animatedSpinner', function () {
    return {
        scope: {
            color: '@'
        },
        restrict: 'E',
        template: `<i class="fa fa-circle-o-notch has-text-{{color || 'primary'}} spinning"></i>`
    }
});


app.directive('setClassAtBottom', function ($window) {
    var $win = angular.element($window); // wrap window object as jQuery object

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var topClass = attrs.setClassAtBottom, // get CSS class from directive's attribute value
                offsetTop = element.offset().top; // get element's offset top relative to document

            $win.on('scroll', function (e) {
                if ($win.scrollTop() >= offsetTop) {
                    element.addClass(topClass);
                } else {
                    element.removeClass(topClass);
                }
            });
        }
    };
});
// Disable default file drop behaviour 
window.addEventListener("dragover", function (e) {
    e = e || event;
    e.preventDefault();
}, false);

window.addEventListener("drop", function (e) {
    e = e || event;
    e.preventDefault();
}, false);

//add string.startsWith for IE 11
if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

angular.element(document).ready( () => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Add a click event on each of them
    $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
            const target = el.dataset.target,
                $target = document.getElementById(target);

            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');
        });
    });
});

//add constants
app.value('resoWebApiUrl', '../RESO/OData/v2');
//app.value('resoWebApiUrl', 'https://dev.update.recolorado.com/RESO/OData/v2');
//app.value('resoWebApiUrl', 'http://localhost:52325/RESO/OData/v2');