app.component('listingInfo', {
    bindings: {
        package: '<',
        alternateAddress: '<',
        agentName: '<',
        agentEmail: '<'
    },
    templateUrl: './App/Listings/ListingInfo.html',
    controller: ['$scope', '$location', 'growl', 'sessions', 'packageService', function ($scope, $location, growl, sessions, packageService) {
        var that = this;

        that.selectedListing = null;
        that.selectedListingId = null;
        that.session = sessions.getCurrent();
        that.deleteReason = null;
        that.showDownloadLink = false;

        that.packageChanges = {
            MediaPackageId = that.package?.MediaPackageId,
            ListingId = null,
            ListingAddressHint = null,
            DownloadPhotosUrl = null,
        };

        that.resetChanges = function () {
            if (!that.package)
                return;

            that.packageChanges = {
                MediaPackageId = that.package.MediaPackageId,
                ListingId = that.package.ListingId,
                ListingAddressHint = that.package.ListingAddressHint,
                DownloadPhotosUrl = that.package.DownloadPhotosUrl
            };

            that.handleDownloadLink();
        }

        that.handleDownloadLink = function () {
            that.showDownloadLink = that.package?.DownloadPhotosUrl
                && (that.package?.PublishedTimestamp || that.package?.MediaStatus == 'Published');
        }

        that.init = function () {
            that.session = sessions.getCurrent();
            that.resetDeleteState();
            that.resetChanges();
            that.handleDownloadLink();
            that.sendPackageDeleteNotification = false;
        }

        that.$onChanges = function (changes) {
            if (changes.package?.currentValue)
                that.resetChanges();
        };

        // Copy Url
        that.copyPackageUrl = function () {
            const tempInput = document.createElement('input');
            tempInput.value = that.package.MediaURL;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            growl.success('Package link copied to clipboard');
        }

        // Update Package
        that.showListingSelect = false;
        that.showEdit = false;
        that.savePackageEdit = function (includeListingId) {
            that.showEdit = false;
            that.showListingSelect = false;
            if (includeListingId && that.selectedListingId)
                that.packageChanges.ListingId = that.selectedListingId;

            packageService.updatePackage({
                package: that.packageChanges,
                onSuccess: _ => {
                    // TODO: get to the heart of shared databinding to avoid $emit/$broadcast
                    $scope.$emit('packageUpdated');
                    growl.success(`Package updated`);
                }
            });
        }

        that.cancelPackageEdit = function () {
            that.showEdit = false;
            that.showListingSelect = false;
            that.resetChanges();
        }

        // Delete Package
        that.resetDeleteState = function () {
            that.isDeleting = false;
            that.showModalDelete = false;
            that.isProcessingPackageDelete = false;
        };

        that.deletePackage = function () {
            that.isDeleting = true;
            packageService.deletePackage({
                packageId: that.package.MediaPackageId,
                reason: that.deleteReason,
                sendNotifcation: that.sendPackageDeleteNotification,
                onSuccess: _ => {
                    growl.success(`Package deleted`);
                    $location.url('/packages');
                },
                onComplete: _ => {
                    that.resetDeleteState();
                }
            });
        };

        that.showPackageHistory = function() {
            $location.url($location.url() + '/history');
        };

        that.init();
    }]
});