app.component('accountSettings', {
    bindings: {},
    templateUrl: './App/Utilities/AccountSettings/AccountSettings.html',
    controller: ['$http', 'sessions', 'growl', function ($http, sessions, growl) {

        var that = this;
        that.session = sessions.getCurrent();
        that.account = null;

        that.getVondorAccount = function () {
            var args = {
                url: './api/vendor',
                method: 'GET',
            },
                success = function (response) {
                    if (response.data && response.data !== '') {
                        that.account = response.data;

                        if (!that.account.NotificationEmails || that.account.NotificationEmails === '')
                            that.account.NotificationEmails = that.account.Email;
                    } 

                    that.isLoading = false;
            },
            error = function (response) {
                that.isLoading = false;
            };

            that.isLoading = true;
            $http(args).then(success, error);
        };

        that.updateVendorAccount = function () {
            var args = {
                url: './api/vendor',
                method: 'PATCH',
                headers: { 'Content-Type': 'text/json' },
                data: that.account
            },
            success = function (response) {
                that.isSaving = false;
                growl.success('Vendor Account Saved');
            },
            error = function (response) {
                that.isSaving = false;
                sessions.reportHttpError(response);
            };

            that.isSaving = true;
            $http(args).then(success, error);
        };

        that.createApplicationKey = function()
        {
            var args = {
                url: './api/vendor/createapplicationkey',
                method: 'POST',
            },
                success = function (response) {
                    that.account.ApplicationKey = response.data;
                    that.isCreatingKey = false;
                    that.showAppKeyConfirm = false;
                    growl.success('API Key Created');
                },
                error = function (response) {
                    that.isCreatingKey = false;
                    sessions.reportHttpError(response);
                };

            that.isCreatingKey = true;
            $http(args).then(success, error);
        }

        that.copyApplicationKey = function () {
            navigator.clipboard.writeText(that.account.ApplicationKey);
        }

        that.resetPassword = function () {
            var url = `./api/PasswordResetService/Request/${that.account.Username}`,
            success = function (response) {
                that.isPasswordReseting = false;
                that.showPasswordResetConfirm = false;
                growl.success('Reset Password - Email Sent');
            },
            error = function (response) {
                that.isPasswordReseting = false;
                that.showPasswordResetConfirm = false;
                $rootScope.$broadcast("httpError", response);
            };

            that.isPasswordReseting = true;
            $http.post(url).then(success, error);
        }

        that.getVondorAccount();
    }]
});