app.component('passwordReset', {
    bindings: {},
    templateUrl: './App/Login/PasswordReset/PasswordReset.html',
    controller: ['$http', '$rootScope', '$scope', '$routeParams', '$location',
        function ($http, $rootScope, $scope, $routeParams, $location) {

        var that = this;

        //Request Mode
        that.disableReset = function () {
            return that.resetIsProcessing === true || that.passwordResetAccount === null || that.passwordResetAccount === '';
        }

        that.disableCancel = function () {
            return that.resetIsProcessing === true;
        }

        that.sendReset = function () {
            var url = `./api/PasswordResetService/Request?account=${that.passwordResetAccount.replace(/\+/g, '%2B') }`,
                success = function (response) {
                    that.resetIsProcessing = false;
                    that.openResetPassword = false;
                    that.openSuccessModal = true;
                },
                error = function (response) {
                    that.resetIsProcessing = false;
                    that.openResetPassword = false;
                    $rootScope.$broadcast("httpError", response);
                };

            that.resetIsProcessing = true;
            $http.post(url).then(success, error);
        }

        that.redirect = function () {
            openSuccessModal = false;
            $location.url('/login');
        }

        //Reset Mode
        that.verify = function(id) {
            var url = `./api/PasswordResetService/Verify/${id}`,
            success = function (response) {
                that.isVerified = true;

                if (response.data.Application == 2) { //'Vtls'
                    that.applicationName = 'Virtual Tour Upload - ';
                } else {
                    that.applicationName = 'Media Bridge - '
                }
            },
            error = function (response) {
                that.redirect();
                $rootScope.$broadcast("httpError", response);
            };

            $http.get(url).then(success, error);
        }

        //password validations
        that.passMatch = function() {
            return that.password && that.password.length > 0 && that.password == that.confirmPassword;
        }

        that.passIsLonghEnough = function() {
            return that.password && (that.useServerValidations ? that.validationResult.IsLongEnough : that.password.length > 7);
        }

        that.passHasLowercase = function() {
            return that.password && (that.useServerValidations ? that.validationResult.HasLowerCase : that.reLower.test(that.password));
        }

        that.passHasUppercase = function() {
            return that.password && (that.useServerValidations ? that.validationResult.HasUpperCase : that.reUpper.test(that.password));
        }

        that.passHasNumber = function() {
            return that.password && (that.useServerValidations ? that.validationResult.HasNumber : that.reNumber.test(that.password));
        }

        that.passHasSpecial = function() {
            return that.password && (that.useServerValidations ? that.validationResult.HasSpecial : that.reSpecial.test(that.password));
        }



        that.passIsValid = function () {
            return that.password &&
                that.confirmPassword &&
                that.passMatch() &&
                that.passIsLonghEnough() &&
                that.passHasLowercase() &&
                that.passHasUppercase() &&
                that.passHasNumber() &&
                that.passHasSpecial();
        }

        that.disable = function () {
            return !that.passIsValid() || that.isProcessing === true || that.isVerified === false;
        }

        that.validatePassword = function (value) {
            if (that.useServerValidations) {
                var url = './api/PasswordResetService/ValidatePassword',
                    success = function (response) {
                        that.validationResult = response.data;
                        that.validationRequestCount--;
                    },
                    error = function (response) {
                        console.log(response);
                        that.validationRequestCount--;
                    };

                that.validationRequestCount++;
                $http({
                    method: "post",
                    url: url,
                    data: value,
                    headers: { 'Content-Type': 'text/plain' }
                }).then(success, error);
            }
        }


        that.reset = function () {
            var url = `./api/PasswordResetService/Reset/${that.requestId}`,
                success = function () {
                    that.isProcessing = false;
                    that.openSuccessModal = true;
                },
                error = function (response) {
                    that.isProcessing = false;
                    that.redirect();
                    $rootScope.$broadcast("httpError", response);
                };

            that.isProcessing = true;
            $http({
                method: "post",
                url: url,
                data: that.password,
                headers: { 'Content-Type': 'text/plain' }
            }).then(success, error);
        }

        that.redirect = function () {
            $location.url('/login');
        }

        that.init = function () {
            that.openSuccessModal = false;
            that.requestId = $routeParams.requestId;
            that.resetMode = that.requestId !== undefined && that.requestId !== null;
            that.isVerified = false;
            that.isProcessing = false;
            that.okMessage = 'An e-mail has been sent with reset instructions';
            that.applicationName = '';

            if (that.resetMode) {
                that.verify(that.requestId);
                that.okMessage = 'The password for your account has been reset';
            }

            that.reLower = /[a-z]/;
            that.reUpper = /[A-Z]/;
            that.reNumber = /[0-9]/;
            that.reSpecial = /[\W_]/;

            that.useServerValidations = true;
            that.validationRequestCount = 0;
            that.validationResult = {
                IsValid: false,
                IsLongEnough: false,
                HasLowerCase: false,
                HasUpperCase: false,
                HasNumber: false,
                HasSpecial: false
            };           
        }

        that.init();
    }]
});