app.component('listingSearchForm', {
    bindings: {
        search: '<',
        onResultsFound: '&',
        onCancel: '&',
        nextListingLink: '='
    },
    templateUrl: './App/Listings/ListingSearchForm.html',
    controller: ['listings', '$scope', 'sessions', function (listings, $scope, sessions) {

        var that = this;
        that.stopSearchFunc = null;

        that.isSearching = false;

        that.searchForListing = function () {

            if ((that.search.listingId === '' || that.search.listingId === null) &&
                (that.search.agentMls === '' || that.search.agentMls === null) && 
                (that.search.coAgentMls === '' || that.search.coAgentMls === null) && 
                (that.search.officeId === '' || that.search.officeId === null) && 
                (that.search.coOfficeId === '' || that.search.coOfficeId === null) && 
                (that.search.streetNumber === '' || that.search.streetNumber === null) && 
                (that.search.streetPrefix === '' || that.search.streetPrefix === null) && 
                (that.search.streetName === '' || that.search.streetName === null) && 
                (that.search.streetSuffix === '' || that.search.streetSuffix === null) && 
                (that.search.unitNumber === '' || that.search.unitNumber === null) && 
                (that.search.city === '' || that.search.city === null) && 
                (that.search.state === '' || that.search.state === null) && 
                (that.search.zip === '' || that.search.zip === null)) {

                that.onError({value: 'Please fill out at least one field to search on'});
                return;
            }

            var session = sessions.getCurrent(),
            search = {
                ListingId: that.search.listingId,
                AgentMlsNumber: that.search.agentMls,
                CoAgentMlsNumber: that.search.coAgentMls,
                OfficeId: that.search.officeId,
                CoOfficeId: that.search.coOfficeId,
                StreetNumber: that.search.streetNumber,
                StreetPrefix: that.search.streetPrefix,
                StreetName: that.search.streetName,
                StreetSuffix: that.search.streetSuffix,
                UnitNumber: that.search.unitNumber,
                City: that.search.city,
                State: that.search.state,
                Zip: that.search.zip
            },
                success = function (response) {

                    that.isSearching = false;
                    that.stopSearchFunc = null
                    that.nextListingLink = null;

                    if (response === null || response.data === null) {
                        that.onResultsFound({ value: [] });
                        that.onError({value: 'There was an internal error'});
                    } else {
                        that.onResultsFound({ value: response.data.value });
                        if (response.data.hasOwnProperty("@odata.nextLink")) {
                            that.nextListingLink = response.data["@odata.nextLink"];
                        }
                    }               
            },
            error = function (response) {
                that.isSearching = false;
                that.stopSearchFunc = null;
                sessions.reportHttpError(response);
            };

            that.isSearching = true;
            that.stopSearchFunc = listings.getListings(session, search, success, error);
        };

        that.cancelSearch = function () {
            that.isSearching = false;

            if (that.stopSearchFunc && typeof that.stopSearchFunc === 'function') {
                that.stopSearchFunc();
            }       
            that.stopSearchFunc = null;

            that.onCancel();
        }
    }]
});