app.controller('tabCtrl', ['$scope', '$rootScope', function ($scope, $rootScope) {

    //$scope.tab = 1;

    $scope.setTab = function (newTab) {
        $scope.tab = newTab;
    };

    $scope.isSet = function (tabNum) {
        return $scope.tab === tabNum;
    };

    $rootScope.$on('setTab', function (event, data) {
        if ($scope.tabGroup === data.tabGroup) {
            $scope.setTab(data.tabNumber);
        }
    });
}]);