app.component('login', {
    bindings: {},
    templateUrl: './App/Login/Login.html',
    controller: ['$http', '$scope', '$rootScope', '$location', '$routeParams', 'sessions', 'configService',
        function ($http, $scope, $rootScope, $location, $routeParams, sessions, configService) {

        var that = this;

        that.init = function () {
            //redirect to main app if user is already logged in
            if (sessions.getCurrent() !== null) {
                $location.url('/packages');
            }

            configService.getConfig((response) => { that.config = response.data; });

            that.username = '';
            that.password = '';
            that.isLogingIn = false;

            $scope.$on('EnterKeyPress', function(e) {
                console.log('Debug: Enter key pressed @ login page');
                that.login();
            });
        }

        that.login = function () {
            var authHash = 'Basic ' + btoa(that.username + ":" + that.password),
                args = {
                url: './api/Login',
                    method: 'GET',
                headers: {
                        'Authorization': authHash
                    }
                },
                success = function (response) { 
                    var session = sessions.create(response.data),
                        redirectUrl = sessions.getRedirect();

                    that.isLogingIn = false;
                    sessions.deleteRedirect();                    
                    $rootScope.$broadcast('loggedIn', session); //Used by nav bar so that it knows when to offer logout
                    $location.url(redirectUrl ? redirectUrl : '/packages');
                },
                error = function (response) {
                    that.isLogingIn = false;

                    if (response.status === 300) {
                        that.redirectToSso();
                    } else {
                        sessions.reportHttpError(response);
                    }
                };

            that.isLogingIn = true;
            sessions.end(); //clear out any existing session before login
            $http(args).then(success, error);
        };

        that.redirectToSso = function () {
            var ssoForm = document.getElementById('sso-form');
            ssoForm.setAttribute('action', that.config.samlIdpFormPostUrl);
            document.getElementById('sso-form-entity-id').setAttribute('value', that.config.samlSpEntityId);
            document.getElementById('sso-form-acs-url').setAttribute('value', that.config.samlSpAcs);
            ssoForm.submit();
        }

        that.logoutConfirm = function () {
            that.confirmationText = 'Are you sure you want to logout?';
            that.confirmationCallback = that.logout;
            that.confirmationModalIsOpen = true;
            that.confirmationOkName = 'Logout';
        }

        that.logout = function () {

            $scope.$broadcast('logout');

            //if (that.cancelGetListings && typeof (that.cancelGetListings) === 'function') { that.cancelGetListings(); }
            //if (that.cancelGetPhotos && typeof (that.cancelGetPhotos) === 'function') { that.cancelGetPhotos(); }
            //if (that.cancelGetTours && typeof (that.cancelGetTours) === 'function') { that.cancelGetTours(); }
            //if (that.cancelGetToursMatrix && typeof (that.cancelGetToursMatrix) === 'function') { that.cancelGetToursMatrix(); }

            sessions.end();
            that.initalize();
        }

        that.requestPassReset = function () {
            $location.url('/password-reset');
        }

        that.init();
    }]
});

//Legacy SSO methods

//that.combinedLogin = function () {
//    var args = {
//        url: `./api/isVendor?name=${that.username}`,
//            method: 'GET'
//        },
//        success = function (response) {
//            if (response.data == 'true') {
//                that.login();
//            } else {
//                //that.oidcRedirect();
//                that.samlRedirect();
//            }
//        },
//        error = function (response) {
//            that.isLogingIn = false;
//            sessions.reportHttpError(response);
//        };
//
//    that.isLogingIn = true;
//    sessions.end(); //clear out any existing session before login
//    $http(args).then(success, error);
//};

//that.oidcRedirect = function () {
//    $window.location.href = '/oidc_login';
//}

//that.samlRedirect = function () {
//    var form = document.getElementById("form_login");
//    form.action = that.config.samlIdpFormPostUrl;
//    form.submit();
//}

//that.checkForSsoHandoff = function () {
//    var sessionId = $cookies.get('sessionId');
//    var ssoUser = $cookies.get('ssoUser');
//    var ssoToken = $cookies.get('ssoToken');
//
//    if (sessionId && ssoToken && ssoUser) {
//        $cookies.remove('sessionId');
//        $cookies.remove('ssoUser');
//        $cookies.remove('ssoToken');
//
//        //get full account/session info
//        $http({ url: './api/VerifySession' }).then(
//            that.finishiSsoLogin,
//            function (response) {
//                sessions.reportHttpError(response);
//            });
//    }
//}

//that.finishiSsoLogin = function (response) {
//    var newSession = sessions.create(response.data),
//        ssoRedirect = sessions.getRedirect();
//
//    $rootScope.$broadcast('loggedIn', newSession); //Used by nav bar so that it knows when to offer logout
//}