const photoStateEnum = Object.freeze({
    loading: 0,
    loaded: 1,
    uploading: 2,
    saving: 3
});

class PtuPhoto {
    constructor(id, fileType, fileName, fileSize, content, order) {
        this.MediaKey = null;
        this.MediaURL = null;
        this.UrlThumbnail = null;
        this.Content = content;
        this.LongDescription = '';
        this.Order = order || 0;
        this.MarkedForRotation = 0;

        this.FileType = fileType;
        this.FileName = fileName;
        this.FileSize = fileSize;

        this.width = null;
        this.height = null;
        this.resolutionValid = true;

        this.IsPublished = false;
        this.PublishId = null;
        this.PublishedOrder = null;

        this.state = photoStateEnum.loaded;
    }

    setResolution(height, width) {
        //todo: should height always be the larger?
        this.height = Math.min(width, height);
        this.width = Math.max(width, height);
    }
}