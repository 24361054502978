app.directive('keyPressEvents', ['$document', '$rootScope', function ($document, $rootScope) {
    return {
        restrict: 'A',
        link: function () {
            $document.bind('keypress', function (e) {
                console.log('Got keypress:', e.which);
                $rootScope.$broadcast('keypress', e);
                $rootScope.$broadcast('keypress:' + e.which, e);
            });
        }
    };
}]);


////Usage example
//// For listening to a keypress event with a specific code
//scope.$on('keypress:13', function (onEvent, keypressEvent) {
//    scope.keyPressed = 'Enter';
//});
//// For listening to all keypress events
//scope.$on('keypress', function (onEvent, keypressEvent) {
//    if (keypress.which === 120) {
//        scope.keyPressed = 'x';
//    }
//    else {
//        scope.keyPressed = 'Keycode: ' + keypressEvent.which;
//    }
//});