app.component('listingSelect', {
    bindings: {
        agentMlsId: '<',
        agentName: '<',
        package: '<',
        selectedListing: '=',
        selectedListingId: '=',
        onFinish: '&',
        onCancel: '&'
    },
    templateUrl: './App/Listings/ListingSelect/ListingSelect.html',
    controller: ['$scope', 'httpWithCancel', 'sessions', 'packageService',
        function ($scope, httpWithCancel, sessions, packageService) {
            var that = this;

            that.init = function () {
                that.isSearching = false;
                that.awaitSearch = null;
                that.listings = [];
                that.session = sessions.getCurrent();
                that.allowAgentIdEdit = false; // that.session.isVendor;
                that.noListingsFound = false;

                if (that.package) {
                    if (that.package.ListingId)
                        return;

                    if (!that.agentMlsId)
                        that.agentMlsId = that.package.MemberMlsId;

                    that.suggestListing();
                }
            };

            that.$onChanges = function (changes) {
                if (changes.package?.currentValue || changes.agentMlsId?.currentValue || changes.selectedListingId?.currentValue)
                    that.init();
            };

            that.getAgents = function () {
                // TODO: limit to agents on listing
            };

            that.getListings = function () {
                if (!that.agentMlsId)
                    return;

                that.selectedListing = null;
                that.selectedListingId = null;
                that.isSearching = true;
                that.noListingsFound = false;
                that.listings = [];
                that.awaitSearch = httpWithCancel.http(
                    {
                        method: 'GET',
                        url: `./api/Agent/${that.agentMlsId}/Listings`
                    },
                    res => {
                        that.listings = res.data;
                        that.isSearching = false;
                        that.noListingsFound = !that.listings || that.listings.length == 0
                    },
                    _ => {
                        that.isSearching = false;
                        that.noListingsFound = true;
                        growl.error(`Error getting listings for ${that.agentMlsId}`);
                    }
                );                
            };

            that.suggestListing = function () {
                that.suggestListingCancel = httpWithCancel.http(
                    {
                        method: 'GET',
                        url: `./api/Agent/${that.agentMlsId}/Listings?addressHint=${that.package.ListingAddressHint}`
                    },
                    res => {
                        if (res.data && res.data.length === 1) {
                            listing = res.data[0];
                            that.selectedListingId = listing.ListingId;
                            that.selectedListing = `${listing.StreetAddress} [#${listing.ListingId}]`;
                        }
                    },
                    _ => {
                        that.isSearching = false;
                        growl.error(`Error getting listings for ${that.agentMlsId}`);
                    }
                );
            }

            that.cancel = function () {
                if (that.awaitSearch)
                    that.awaitSearch();

                that.awaitSearch = null;

                if (that.onCancel)
                    that.onCancel();
            };

            that.onFinish = function (response) {
                console.log(response);
            };

            $scope.$on('EnterKeyPress', function (e) {
                //console.log('listing select enter press');
                that.getListing();
            });

            $scope.$on('logout', function () {
                that.cancel();
            });

            that.init();
        }]
});