const authTypeEnum = Object.freeze({
    sso: 0,
    basic: 1
});

class PtuSession {
    constructor(username, userType, authType) {
        this.username = username;
        this.userType = userType;
        this.authType = authType;
    }
}