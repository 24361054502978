app.component('packageHistory', {
    bindings: { },
    templateUrl: './App/Packages/History/PackageHistory.html',
    controller: ['$routeParams', '$location', 'packageService', function ($routeParams, $location, packageService) {
        var that = this;

        that.loadHistory = function () {
            var success = function (response) {
                that.history = response.data;
                that.loadingHistory = false;
            }, error = function () {
                that.loadingHistory = false;
            };

            that.loadingHistory = true;
            packageService.getHistory(that.packageId, success, error);
        }

        that.loadPackage = function () {
            var request = {
                packageId: that.packageId,
                onSuccess: function (response) {
                    that.package = response;
                }
            };

            packageService.getPackage(request);
        }

        that.back = function () {
            return $location.url().replace('/history', '');
        }

        that.init = function () {
            that.history = [];
            that.package = null;
            that.packageId = parseInt($routeParams.packageId, 10);
            that.loadPackage();
            that.loadHistory();
        };

        that.init();
    }]
});