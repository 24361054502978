app.service('configService', ['$http', '$rootScope', '$q', function ($http, $rootScope, $q) {

    var that = this;

    that.getConfig = function (success, error) {

        if ($rootScope.ptuConfig === null || $rootScope.ptuConfig === undefined) {
            var innerSuccess = function (response) {
                $rootScope.ptuConfig = response.data;
                success(response);
            },
                innerError = function (response) {
                    error(response);
                };

            $http.get(`./api/PtuConfig`).then(innerSuccess, innerError);
        } else {
            success({ "data": $rootScope.ptuConfig });
        }
    };
}]);