app.component('listingLink', {
    bindings: {
        show: '=',
        onComplete: '&'
    },
    templateUrl: './App/Listings/ListingLink/ListingLink.html',
    controller: ['$location', '$routeParams', 'sessions', '$http', '$anchorScroll', '$timeout', 'listings', function ($location, $routeParams, sessions, $http, $anchorScroll, $timeout, listings) {
        var that = this;

        that.init = function () {
            that.session = sessions.getCurrent();
            that.packageId = parseInt($routeParams.packageId, 10);
            that.mode = 'searchForAgent';
            that.selectedAgent = null;
        };

        that.selectAgent = function (agent) {
            that.selectedAgent = agent;
            that.mode = 'searchForListing';
        }

        that.selectListing = function (listing) {
            that.selectedListing = listing;
            that.openConfirmLinkModal = true;
            that.mode === 'isLinking';
        }

        that.cancel = function () {
            $location.url('/packages');
        }

        that.listingsFound = function (results) {
            that.listings = results;
            that.searchComplete = true;
            //that.scrollToResults();
        }

        that.confirmLinkListing = function (listing) {
            that.processingLinkRequest = false;
            that.selectedListing = listing;
            that.openConfirmLinkModal = true;
        }

        that.linkListing = function () {
            var url = `./api/v1/MediaPackages(${that.packageId})/LinkListing/${that.selectedListing.ListingId}`,
                data = {},
                args = {},
                success = function (response) {
                    that.processingLinkRequest = false;
                    that.openConfirmLinkModal = false;
                    that.openSuccesModal = true;

                    var index = that.session.packages.findIndex(p => p.MediaPackageId === that.packageId);
                    that.session.packages[index] = response.data;
                    sessions.update(that.session);

                    that.show = false;
                    that.onComplete({ package: response.data });
                },
                error = function (response) {
                    that.openConfirmLinkModal = false;
                    that.processingLinkRequest = false;
                    sessions.reportHttpError(response);
                };

            that.processingLinkRequest = true;
            $http.post(url, data, args).then(success, error);
        }

        that.back = function () {
            that.show = false;
        }

        that.init();
    }]
});
