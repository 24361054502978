app.component('emailSubscription', {
    bindings: {},
    templateUrl: './App/Utilities/EmailSubscription/EmailSubscription.html',
    controller: ['sessions', '$http', '$routeParams', '$location', function (sessions, $http, $routeParams, $location) {
        var that = this;

        that.init = function () {
            that.subscription = {
                "Email": "",
                "Type": 0,
                "TimeStamp": Date.now()
            };
            that.hash = $routeParams.hash;
            that.session = sessions.getCurrent();
            that.isSaving = false;
            that.isLoading = false;

            if (that.session || that.hash) {
                that.load();
            } else {
                $location.url('/login');
            }
        };

        that.load = function () {
            var url = `./api/EmailSubscriptionService`,
            args = {},
            success = function (response) {
                that.subscription = response.data;
                that.isLoading = false;
            },
            error = function (response) {
                that.isLoading = false;
                sessions.reportHttpError(response);
            };

            if (that.hash) {
                url += `/encrypted?hash=${encodeURIComponent(that.hash)}`;
            } else {
                args = { };
            }

            that.isLoading = true;
            $http.get(url, args).then(success, error);
        }


        that.save = function () {
            var url = `./api/EmailSubscriptionService`,
                data = that.subscription,
                args = {},
                success = function (response) {
                    that.isSaving = false;
                },
                error = function (response) {
                    that.isSaving = false;
                };

            if (that.hash) {
                url += `/encrypted?hash=${encodeURIComponent(that.hash)}`;
            } else {
                args = { };
            }

            that.isSaving = true;
            $http.post(url, data, args).then(success, error);
        }

        that.init();
    }]
});