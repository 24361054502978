app.component('publishPermissions', {
    bindings: {},
    templateUrl: './App/Utilities/PublishPermissions/PublishPermissions.html',
    controller: ['$http', 'sessions', 'growl', function ($http, sessions, growl) {

        var that = this;

        that.loadGrants = function () {
            var args = {
                url: './api/VendorPermissionService/grants',
                method: 'GET',
            },
            success = function (response) {
                if (response.data && response.data !== '') {
                    that.grants = response.data;
                } else {
                    that.grants = [];
                }

                that.isLoading = false;
            },
            error = function (response) {
                that.isLoading = false;
            };

            that.isLoading = true;
            $http(args).then(success, error);
        }

        that.onChange = function (grant) {
            var args = {
                url: './api/VendorPermissionService/grants',
                method: 'PUT',
                data: grant
            },
            success = function (response) {
                grant.isLoading = false;
            },
            error = function (response) {
                grant.isLoading = false;
            };

            grant.isLoading = true;
            $http(args).then(success, error);
        }

        that.init = function () {
            that.session = sessions.getCurrent();
            that.isLoading = true;
            that.grants = [];

            that.loadGrants();
        }

        that.init();
    }]
});