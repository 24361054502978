app.component('photoManager', {
    bindings: {
        package: '=',
        session: '=',
        config: '<',
        minImageHeight: '<',
        minImageWidth: '<',
        softLimit: '<',
        delta: '<'
    },
    templateUrl: './App/Photos/PhotoManager.html',
    controllerAs: 'pm',
    controller: ['$scope', '$http', '$routeParams', 'growl', 'sessions', 'photoService', 'packageService',
        function ($scope, $http, $routeParams, growl, sessions, photoService, packageService) {
        var that = this;

        that.stateEnum = photoStateEnum;
        that.showExcluded = false;
        that.excludedCount = 0;
        that.session = sessions.getCurrent();

        that.$onInit = function () {
            that.packageId = parseInt($routeParams.packageId, 10);
            that.softLimit = that.config ? that.config.softLimit : 40; // TODO: config is an async request in the parent and not always available... softLimit should be a build-time constant

            that.refreshDerived();
            that.toggleZoom(0);
            sessions.initDropdowns();
        };

        that.photoCount = function (includeExcluded = false) {
            if (!(that.package && that.package.Photos)) { return 0; }

            var photos = includeExcluded ? that.package.Photos : that.package.Photos.filter(p => !p.MarkedForDeletion);
            return photos.length;
        }

        that.displayedPhotoCount = function (includeExcluded) {
            var photoCount = that.photoCount();
            return photoCount > that.softLimit ? that.softLimit : photoCount;
        }

        that.zoomLevels = ['zoom-small', 'zoom-medium', 'zoom-large'];
        that.zoomLevel = 0;
        that.toggleZoom = function (override) {
            that.zoomLevel = (that.zoomLevel + (override ?? 1)) % that.zoomLevels.length;
        }

        that.hasWarnings = function (photo) {
            return photo && ((photo.Errors && photo.Errors.length) || (photo.Warnings && photo.Warnings.length));
        }

        that.printWarnings = function (photo) {
            if (!photo) return;

            var warnings = [];
            if (photo.Errors && photo.Errors.length)
                warnings = warnings.concat(photo.Errors);
            if (photo.Warnings && photo.Warnings.length)
                warnings = warnings.concat(photo.Warnings);

            return warnings.join('. ');
        }

        // Photo Modes
        that.photoMode = '';
        that.isModeEdit = false;
        that.isModeArrange = false;
        that.isModeHide = false;
        that.isModeDelete = false;

        that.outsideClickHandler = function (e) {
            let evt = e || window.event;
            if (!evt.target ||
                (!evt.target.classList.contains('textarea')
                    && !evt.target.classList.contains('photo-grid-zoom')
                    && !evt.target.closest('.photo-control')
                    && !evt.target.closest('.quick-edit'))) {

                $scope.$apply(function () { that.setPhotoMode(''); });
                document.removeEventListener('click', that.outsideClickHandler);
                evt.preventDefault();
                return false;
            }
        };

        that.bindCloseOnOutsideClick = function () {
            document.addEventListener('click', that.outsideClickHandler);
        };

        that.setPhotoMode = function (photoMode) {
            that.previousMode = that.photoMode;
            that.photoMode = photoMode;
            that.isModeEdit = false;
            that.isModeArrange = false;
            that.isModeHide = false;
            that.isModeDelete = false;        

            switch (that.photoMode) { // labels must match the dropdown#photo-mode
                case 'Arrange':
                    that.isModeArrange = true;
                    that.selectedPhoto = null;
                    break;
                case 'Filter':
                    that.isModeHide = true;
                    that.selectedPhoto = null;
                    break;
                case 'Delete':
                    that.isModeDelete = true;
                    that.selectedPhoto = null;
                    break;
                case 'Edit':
                //default:
                    that.isModeEdit = true;
                    that.bindCloseOnOutsideClick();
                    break;
                default:
                    that.selectedPhoto = null;
                    break;
            }
        };

        that.handlePhotoClick = function (photo) {
            if (that.isModeHide)
                that.toggleExcludePhoto(photo, true);
            else if (that.isModeDelete)
                that.toggleDeletePhoto(photo);
            else if (that.isModeEdit) {
                that.selectedPhoto = angular.copy(photo);
            }
        }

        // Mode: Bulk Delete
        that.toggleDeletePhoto = function (photo) {
            photo.isHardDelete = !photo.isHardDelete;
        };

        that.hasPublishedPhotos = function () {
            return that.package && that.package.Photos ? that.package.Photos.filter(p => p.IsPublished).length : 0;
        }

        that.photosToDelete = function () {
            return that.package && that.package.Photos ? that.package.Photos.filter(p => p.isHardDelete) : [];
        }

        that.selectAllDelete = function (markDelete, onlyPublished, onlyAboveLimit) {
            return that.package && that.package.Photos && that.package.Photos.forEach((p, idx) => {
                if (onlyPublished) {
                    p.isHardDelete = p.IsPublished;
                } else if (onlyAboveLimit) {
                    p.isHardDelete = idx > (that.softLimit - 1);
                } else {
                    p.isHardDelete = markDelete;
                }   
            });
        }

        that.showBulkDeleteConfirm = false;
        that.isProcessingBulkDeletePhoto = false;
        that.exitDeleteMode = function (performDeletions) {
            that.isModeDelete = false;            
            that.showExcluded = false;

            if (performDeletions) {
                that.isProcessingBulkDeletePhoto = true;
                var mediaKeys = [];
                that.photosToDelete().forEach(p => {
                    mediaKeys.push(p.MediaKey);
                });

                photoService.deletePhoto(
                    that.session,
                    that.package.MediaPackageId,
                    mediaKeys.join(','),
                    onSuccess => {
                        mediaKeys.forEach(mediaKey => {
                            var photo = that.package.Photos.find(p => p.MediaKey.toString() == mediaKey);
                            if (photo) {
                                that.package.Photos.splice(that.package.Photos.indexOf(photo), 1);
                            }
                        });
                        growl.success('Photo deleted');

                        that.selectedPhoto = null;
                        that.selectedForDelete = null;
                        that.isProcessingBulkDeletePhoto = false;
                        that.showBulkDeleteConfirm = false;
                        that.sortExcluded();
                        that.refreshDerived();
                        that.updatePhotoSession();
                        that.setPhotoMode(that.previousMode);
                    },
                    onError => {
                        that.selectedPhoto = null;
                        that.selectedForDelete = null;
                        that.isProcessingBulkDeletePhoto = false;
                        that.showBulkDeleteConfirm = false;
                        that.sortExcluded();
                        that.refreshDerived();
                        that.updatePhotoSession();
                        sessions.reportHttpError(onError);
                        that.setPhotoMode(that.previousMode);
                    }
                );
            } else {
                that.photosToDelete().forEach(p => {
                    p.isHardDelete = false;
                });
                that.setPhotoMode(that.previousMode);
            }
        }

        that.isProcessingDeletePhoto = false;
        that.deletePhoto = function (photoToDelete) {
            if (!photoToDelete)
                return;

            var photoMediaKey = photoToDelete.MediaKey.toString();
            photoToDelete.state = that.stateEnum.saving;
            that.isProcessingDeletePhoto = true;

            photoService.deletePhoto(
                that.session,
                that.package.MediaPackageId,
                photoToDelete.MediaKey,
                onSuccess => {
                    var photo = that.package.Photos.find(p => p.MediaKey.toString() == photoMediaKey);
                    if (photo) {
                        that.package.Photos.splice(that.package.Photos.indexOf(photo), 1);
                    }
                    growl.success('Photo deleted');
                    //console.log('onDelete', photo, onSuccess);

                    that.selectedPhoto = null;
                    that.selectedForDelete = null;
                    that.isProcessingDeletePhoto = false;
                    that.updatePhotoSession();
                },
                onError => {
                    that.selectedPhoto = null;
                    that.selectedForDelete = null;
                    that.isProcessingDeletePhoto = false;
                    sessions.reportHttpError(onError);
                }
            );
        }

        that.sortExcluded = function (suppressNotification) {
            const pad = val => val.toString().padStart(3, '0');
            that.package.Photos.sort((p1, p2) => {
                const p1str = `${p1.MarkedForDeletion}${pad(p1.Order)}`,
                    p2str = `${p2.MarkedForDeletion}${pad(p2.Order)}`;

                return p1str < p2str ? -1 : 1;
            });

            that.reSortFinished();
        }

        that.photoLimitIndex = function () {
            return Math.max(that.lastIncludedIndex, that.softLimit - 1);
        }

        that.refreshDerived = function () { // TODO: a more angular approach here...
            if (!(that.package && that.package.Photos)) { return; }

            that.excludedCount = that.package.Photos.filter(p => p.MarkedForDeletion).length;
            that.lastIncludedIndex = -1;
            that.includedCount = 0;

            that.package.Photos.forEach((p, idx) => {
                if (p.MarkedForDeletion)
                    that.hasExcluded = true;
                else if (that.includedCount < that.softLimit) {
                    that.includedCount++;
                    that.lastIncludedIndex = idx;
                }
            });
        }

        // Per-Photo methods

        that.allowDelete = function (photo) {
            return photo && !photo.IsPublished && photo.MarkedForDeletion && !that.session.isVendor;
        }

        that.allowRotate = function (photo) {
            return photo;
        }

        that.promptDelete = function (photo) {
            that.selectedForDelete = photo;
            //that.selectedPhoto = null;
        }

        that.updatePhotoSession = function () {
            that.refreshDerived();
            that.session = sessions.updatePackage(that.package);
        }

        // TODO: resorting should be serverside with a callback to update local order
        // see if sending single reOrdered photo with serverside handling improves this
        that.reSortFinished = function (response) {
            that.package.Photos.forEach((photo, index) => {
                if (photo.Order == index)
                    return;

                photo.Order = index;
                var success = function (response) {
                        photo.state = that.stateEnum.loaded;
                        that.updatePhotoSession();
                        //response will only be null with a mass delete triggers resort
                        if (response) { growl.success('Photo moved'); }                        
                    },
                    error = function (response) {
                        photo.state = that.stateEnum.loaded;
                        sessions.reportHttpError(response);
                    };

                photo.state = that.stateEnum.saving;

                photoService.patchPhoto( // TODO: check if changed...
                    that.session,
                    that.package.MediaPackageId,
                    photo.MediaKey,
                    "Order",
                    photo.Order,
                    success,
                    error
                );
            });
        }

        that.toggleExcludePhoto = function (photo, immediateChange) {
            photo.MarkedForDeletion = !photo.MarkedForDeletion;
            if (immediateChange)
                that.markPhotoDeleted(photo);
            else if (that.showExcluded) {
                var idx = that.photosToDelete.indexOf(photo);
                if (idx >= 0)
                    that.photosToDelete.splice(idx, 1);
                else
                    that.photosToDelete.push(photo);
            }
        };

        that.markPhotoDeleted = function (photo) {
            photo.state = that.stateEnum.saving;

            photoService.patchPhoto(
                that.session,
                that.package.MediaPackageId,
                photo.MediaKey,
                "MarkedForDeletion",
                photo.MarkedForDeletion,
                onSuccess => {
                    photo.state = that.stateEnum.loaded;
                    that.updatePhotoSession();
                },
                onError => {
                    photo.state = that.stateEnum.loaded;
                    //sessions.reportHttpError(onError);
                }
            );
        }

        that.selectedPhoto = null;
        that.selectPhoto = function (photo) {
            if (that.isModeDelete)
                return false;

            that.selectedPhoto = angular.copy(photo);
            that.setPhotoMode('Edit');

            return true;
        };

        that.handleScrollToElem = function ($elem) {
            if ($elem /*&& window.innerWidth <= 1024*/)
                $elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        };

        that.handleCaptionModal = function (photo) {
            if (!photo)
                return true;

            const index = that.package.Photos.indexOf(that.package.Photos.find(p => p.MediaKey == photo.MediaKey)),
                getRekt = (cssSel) => document.querySelector(cssSel)?.getBoundingClientRect(),
                photoRect = getRekt(`.photobox-${index}`),
                $modal = document.querySelector('.quick-edit'),
                modalRect = $modal.getBoundingClientRect(),
                leftOffset = window.scrollX + photoRect.left,
                topOffset = window.scrollY + photoRect.top,
                widthOffset = (modalRect.width - photoRect.width) * 0.5,
                heightOffset = (modalRect.height - photoRect.height) * 0.5;

            if (!modalRect.top)
                return true;

            $modal.style.left = `${leftOffset - widthOffset}px`;
            $modal.style.top = `${topOffset - heightOffset}px`;

            const $textbox = $modal.querySelector('textarea');
            if (!document.activeElement == $textbox) {
                $textbox.focus();
                that.handleScrollToElem($textbox);
            }
            return true;
        };

        that.handlePhotoCaptionKeyHandler = function (evt, photo) {
            const keyCode = evt.keyCode || evt.which;
            if (keyCode == 27) { // Escape
                evt.preventDefault();
                that.setPhotoMode('');
                return;
            }
            
            if (keyCode != 13 && keyCode != 9) { // Enter or Tab
                photo.hasChanges = true;
                return;
            }

            evt.preventDefault();
            let index = that.package.Photos.indexOf(that.package.Photos.find(p => p.MediaKey == photo.MediaKey));
            
            var nextIndex = evt.shiftKey ? index - 1 : index + 1;
            if (nextIndex < 0)
                nextIndex = that.package.Photos.length - 1; // that.package.Photos[that.package.Photos.length - 1].Order;
            if (nextIndex > that.package.Photos.length - 1)
                nextIndex = nextIndex % (that.package.Photos.length);

            if (photo.hasChanges)
                that.savePhoto(photo, true, true);

            const $photoElem = document.querySelector(`.photobox-${nextIndex} .photo-control`);
            $photoElem?.click();
            that.handleScrollToElem($photoElem);
        };

        that.rotatePhoto = function (photo) {
            photo.MarkedForRotation = (photo.MarkedForRotation + 90) % 360;
            return photo;
        };

        that.savePhoto = function (photoToSave, keepSelectedPhoto, forceUpdate) {
            console.log('savePhoto', photoToSave)
            if (!photoToSave)
                return;

            var photo = that.package.Photos.find(p => p.MediaKey == photoToSave.MediaKey);
            if (!photo)
                return;

            var data = {};
            ['LongDescription', 'MarkedForRotation', 'Order', 'MarkedForDeletion'].forEach(prop => {
                if (photo[prop] != photoToSave[prop])
                    data[prop] = photo[prop] = photoToSave[prop];
            });

            if (!forceUpdate && !Object.keys(data).length) {
                if (!keepSelectedPhoto)
                    that.selectedPhoto = null;
                that.isProcessingSelectedPhoto = false;
                // no changes
                return;
            }

            photo.state = that.stateEnum.saving;
            that.isProcessingSelectedPhoto = true;

            $http.patch(
                `./api/v1/MediaPackages(${that.package.MediaPackageId})/Photos(${photo.MediaKey})`,
                data,
                { headers: { 'DisableAutoResort': !data.Order } }
            ).then(res => {
                photo.state = that.stateEnum.loaded;
                if (!keepSelectedPhoto)
                    that.selectedPhoto = null;
                that.isProcessingSelectedPhoto = false;
                growl.success('Photo updated');
            })
            .catch(err => { sessions.reportHttpError(err); });            
        }

        that.hasImportablePhotos = function () {
            return that.delta && that.delta.HasWarning === true;
        }

        that.disableImportPhotos = function () {
            return that.isImportingMlsPhotos === true;
        }

        that.importExistingPhotos = function () {
            that.isImportingMlsPhotos = true;
            packageService.importMlsPhotos({
                package: that.package,
                onSuccess: pkg => {
                    growl.success(`${pkg.Photos.length} photos imported`);
                }
            });
        }
    }]    
});
