app.controller('emailModalCtrl', [ '$scope', '$http', function ($scope, $http) {

    $scope.emailModalIsOpen = false;

    $scope.$on('sendEmail', function (event, data) {

        $scope.to = data.to;

        $scope.emailModalIsOpen = true;
    });

    $scope.send = function () {
        var args = {
            url: './Email/Send',
            method: 'POST',
            data: {
                To: $scope.to,
                //From: $scope.,
                CC: $scope.cc,
                Title: $scope.subject,
                Body: $scope.body
            }
        },
            success = function (response) {
                //todo: setup modals and logging for success and error reponses
            },
            error = function (response) {

            };

        $http(args).then(success, error);

        $scope.to = '';
        $scope.cc = '';
        $scope.subject = '';
        $scope.body = '';

        $scope.emailModalIsOpen = false;
    };

}]);