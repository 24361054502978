app.component('sessionManager', {
    bindings: {},
    templateUrl: './App/Session/SessionManager.html',
    controller: ['$location', '$document', '$rootScope', '$scope', 'growl', 'sessions', 'configService',
        function ($location, $document, $rootScope, $scope, growl, sessions, configService) {

        var that = this;

        that.errorHandler = function (message, callback) {
            that.errorMessage = message;
            that.onErrorClose = typeof callback === 'function'
                ? function () { that.openErrorModal = false; callback(); }
                : function () { that.openErrorModal = false; };

            console.log(`Error: ${that.errorMessage}`);
            that.openErrorModal = true;
        }

        that.showError = function (message, redirect) {
            growl.error(message);
            if (redirect)
                $location.url(redirect);
        }

        that.loginRedirect = function (isTimeout) {
            // that.errorHandler('Your session has expired. Please login again.', function () { $location.url('/login'); });
            $location.url('/login');
            if (isTimeout)
                growl.info('Your session has expired, please login.');
        };

        that.timeoutHandler = function (destination) {
            sessions.saveRedirect(destination);
            if (sessions.getCurrent()) {
                sessions.end(); // prevents multiple error modals when async operations trigger many timeout calls
                that.loginRedirect(true);
            } else {
                that.loginRedirect(false);
            }
        }

        $rootScope.$on("error", function (event, data) {
            that.errorHandler(data.message, data.callback);
        });

        $rootScope.$on("httpError", function (event, httpResponse) {
            console.log(`Debug: HTTP Error Response - Status:${httpResponse.status} StatusText:${httpResponse.statusText} Message:${httpResponse.data.Message}`);

            var message = httpResponse.data.Message ?? httpResponse.data ?? httpResponse.statusText,
                callback = null;

            switch (httpResponse.status) {
                case 401: //Unauthorized
                    message = httpResponse.data ?? 'Incorrect Username or Password';
                    callback = function () {
                        var session = sessions.getCurrent();
                        if (session) {
                            sessions.end();
                        }
                        $location.path('/login');
                    };
                    break;
                case 403: // Forbidden
                    callback = function () {
                        $location.url('/packages');
                    };
                    break;
                case 410: // Unavailable
                    return that.showError(httpResponse.data, '/packages');
                    break;
                case 413: //Request Entity Too Large
                    var fileSize = that.config['maxFileSize'];
                    message = `Photo is too large.  Please submit photos under ${fileSize}.`;
                    break;
                case 440: //Session Timeout
                    that.timeoutHandler($location.path());
                    return;
                //400 Bad Request
                //404 Not Found
                //500 Internal Server Error
                default:
                    break;
            }
            that.errorHandler(message, callback);
        });

        $rootScope.$on("sessionTimeout", function (event, redirect) {
            that.timeoutHandler(redirect);
        });

        that.init = function () {
            var pathIsLogin = $location.path() === '/login',
                pathIsAllAccess = $location.path().startsWith('/password-reset')
                    || $location.path().startsWith('/email-subscription/link')
                    || $location.path().startsWith('/saml'),
                session = sessions.getCurrent(),
                destination = $location.path()
                deeplink = sessions.getRedirect();

            that.openErrorModal = false;

            configService.getConfig(function (response) { that.config = response.data; });

            if (!session && !pathIsLogin && !pathIsAllAccess) {
                sessions.reportInvalidSession(destination);
            } else if (session !== null && pathIsLogin) {
                $location.url('/packages');
            } else if (deeplink) {
                sessions.deleteRedirect();
                $location.url(deeplink);
            }

            //broadcast any enter key presses
            $document.bind('keyup', function (e) {
                if (e.keyCode === 13) {
                    $rootScope.$broadcast('EnterKeyPress');
                }
            })

            //close any open error modals on enter keypress
            //$scope.$on('EnterKeyPress', function (e) {
            //    that.openErrorModal = false;
            //});
        }

        that.init();
    }]
});