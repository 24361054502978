app.service('listings', ['httpWithCancel', 'resoWebApiUrl', 'sessions', function (httpWithCancel, resoWebApiUrl, sessions) {

    var that = this;

    that.getListings = function (session, search, success, error) {

        var searchParameters = [],
            args = {
                method: 'GET'
            },
            statusQuery = '(MlsStatus eq ACT or MlsStatus eq INC or MlsStatus eq CSN or MlsStatus eq PND)'; //or (MlsStatus eq WTH and WithdrawnDate eq 2013-01-01-TODAY)

        if (search.ListingId) {
            searchParameters.push(`ListingId eq ${that.escapeSpecialChars(search.ListingId)}`);
        }
        if (search.AgentMlsNumber) {
            searchParameters.push(`ListAgentMlsId eq ${that.escapeSpecialChars(search.AgentMlsNumber)}`);
        }
        if (search.CoAgentMlsNumber) {
            searchParameters.push(`CoListAgentMlsId eq ${that.escapeSpecialChars(search.CoAgentMlsNumber)}`);
        }
        if (search.OfficeId) {
            searchParameters.push(`ListOfficeMlsId eq ${that.escapeSpecialChars(search.OfficeId)}`);
        }
        if (search.CoOfficeId) {
            searchParameters.push(`CoListOfficeMlsId eq ${that.escapeSpecialChars(search.CoOfficeId)}`);
        }
        if (search.StreetNumber) {
            searchParameters.push(`StreetNumber eq ${that.escapeSpecialChars(search.StreetNumber)}`);
        }
        if (search.StreetPrefix) {
            searchParameters.push(`StreetDirPrefix eq ${that.escapeSpecialChars(search.StreetPrefix)}`);
        }
        if (search.StreetName) {
            searchParameters.push(`StreetName eq ${that.escapeSpecialChars(search.StreetName)}`);
        }
        if (search.StreetSuffix) {
            searchParameters.push(`StreetSuffix eq ${that.escapeSpecialChars(search.StreetSuffix)}`);
        }
        if (search.UnitNumber) {
            searchParameters.push(`UnitNumber eq ${that.escapeSpecialChars(search.UnitNumber)}`);
        }
        if (search.City) {
            searchParameters.push(`City eq ${that.escapeSpecialChars(search.City)}`);
        }
        if (search.State) {
            searchParameters.push(`StateOrProvince eq ${that.escapeSpecialChars(search.State)}`);
        }
        if (search.Zip) {
            searchParameters.push(`PostalCode eq ${that.escapeSpecialChars(search.Zip)}`);
        }       

        args.url = `./api/odata/listings?$filter=${searchParameters.join(' and ')} and ${statusQuery}&$top=15`;

        return httpWithCancel.http(args, success, error);
    }

    that.getMoreListings = function (session, nextListingLink, success, error) {
        var args = {
            url: nextListingLink,
            method: 'GET'
        };

        return httpWithCancel.http(args, success, error);
    }

    that.newSearch = function () {
        return {
            listingId: null,
            agentMls: null,
            coAgentMls: null,
            officeId: null,
            coOfficeId: null,
            streetNumber: null,
            streetPrefix: null,
            streetName: null,
            streetSuffix: null,
            unitNumber: null,
            city: null,
            state: null,
            zip: null,
            streetPrefixes: ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'],
            streetSuffixes: ['ALY', 'ANX', 'ARC', 'AVE', 'AVECT', 'AVEDR', 'AVEPKWY', 'AVEPL', 'AVERD', 'AVEWAY', 'BLF', 'BLVD', 'BRG', 'BRK', 'BYP', 'CIR', 'CIRDR', 'CLB', 'CLF', 'CRK', 'CRST', 'CT', 'CTR', 'CV', 'CYN', 'DM', 'DR', 'ESTS', 'EXPY', 'FLD', 'FRD', 'FRK', 'FRST', 'GDNS', 'GLN', 'GRV', 'HL', 'HLS', 'HOLW', 'HTS', 'HWY', 'ISLE', 'JCT', 'KNL', 'LAND', 'LK', 'LKS', 'LN', 'LNDG', 'LOOP', 'MDWS', 'ML', 'MT', 'MTN', 'PARK', 'PASS', 'PATH', 'PIKE', 'PKWY', 'PL', 'PLCT', 'PLZ', 'PNE', 'PNES', 'PT', 'RD', 'RDG', 'RDS', 'RIV', 'RNCH', 'ROW', 'RUN', 'SMT', 'SPG', 'SPGS', 'SPUR', 'SQ', 'ST', 'STCIR', 'STCT', 'STDR', 'STLN', 'STPL', 'STRD', 'STWAY', 'TER', 'TRCE', 'TRL', 'UN', 'VIS', 'VLG', 'VLY', 'VW', 'WALK', 'WAY', 'WLS', 'XING'],
            states: ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY']
        };
    }

    that.escapeSpecialChars = function (item) {
        return item.replace('%', '%25')
            .replace('#', '%23')
            .replace('$', '%24')
            .replace('&', '%26')
            .replace('(', '%28')
            .replace(')', '%29')
            .replace('@', '%40');
    }
}]);