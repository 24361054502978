app.service('packageService',
    ['httpWithCancel', '$location', 'growl', 'sessions',
    function (httpWithCancel, $location, growl, sessions) {
        var that = this;

        // TODO: make this an external generic cache for all heavy assets?
        class PackageCache {
            constructor() {
                this._cache = [];
            }

            update(package) {
                var index = this._cache.findIndex(p => p.MediaPackageId === package.MediaPackageId);
                if (index < 0) {
                    this._cache.unshift(package);
                } else {
                    this._cache[index] = package;
                }
                sessions.updatePackage(package); // TODO: keep both caches in sync to minimize transition
            }

            updateAll(packageList) {
                this._cache = packageList ?? [];
            }

            get(mediaPackageId) {
                return this._cache.find(p => p.MediaPackageId === mediaPackageId) ?? null;
            }

            getAll() {
                return this._cache;
            }

            exists(mediaPackageId) {
                return this.get(mediaPackageId) !== null;
            }

            remove(mediaPackageId) {
                const index = this._cache.findIndex(p => p.MediaPackageId === mediaPackageId);
                if (index !== -1)
                    this._cache.splice(index, 1);
            }

            count() {
                return this._cache.length;
            }

            clear() {
                this._cache = [];
            }
        }

        that.cache = new PackageCache();

        that.callback = function (response, func) {
            if (typeof func == 'function')
                return func(response);
            return func;
        }

        that.handleCallbacks = function (data, onSuccess, onComplete) {
            that.callback(data, onSuccess);
            that.callback(data, onComplete);
        };

        // handle results where ODataError is expected, fallback on json/text
        that.errorHandler = function (response, onError, onComplete) {
            let handled = false;
            var errMsg = response?.data?.Message ?? response?.data ?? response;
            if (!errMsg || errMsg.startsWith('<'))
                errMsg = response.statusText;

            that.callback(onError, errMsg);
            that.callback(onComplete, response);

            if (response.status == 401) {
                //growl.error(`Not authorized to view package`);
                $location.url('/packages');
            }

            if (response.status == 403
                || response.status == 410
                || response.status == 440) {
                sessions.reportHttpError(response);
                return true;
            }

            growl.error(`Error: ${errMsg}`);
            return true;
        }

        that.getPackages = function (request) {
            if (that.cache.count() > 0)
                that.handleCallbacks(that.cache.getAll(), request.onSuccess, request.onComplete);

            return httpWithCancel.http(
                {
                    method: 'GET',
                    url: `./api/v1/MediaPackages?$Expand=Listing,Photos,Tours`
                },
                res => {
                    that.cache.updateAll(res.data?.value);
                    that.handleCallbacks(that.cache.getAll(), request.onSuccess, request.onComplete);
                },
                err => that.errorHandler(err, request.onError, request.onComplete)
            );
        }

        that.getPackage = function (request) {
            if (that.cache.exists(request.packageId))
                that.handleCallbacks(that.cache.get(request.packageId), request.onSuccess, request.onComplete);

            return httpWithCancel.http(
                {
                    method: 'GET',
                    url: `./api/v1/MediaPackages(${request.packageId})?$Expand=Listing,Photos,Tours`
                },
                res => {
                    that.cache.update(res.data);
                    that.handleCallbacks(res.data, request.onSuccess, request.onComplete);
                },
                err => that.errorHandler(err, request.onError, request.onComplete)
            );
        }

        that.createPackage = function (request) {
            const params = request.importExistingMedia ? '&importExistingMedia=true' : '';
            return httpWithCancel.http(
                {
                    method: 'POST',
                    url: `./api/v1/MediaPackages?$Expand=Listing,Photos,Tours${params}`,
                    data: {
                        "ListingId": request.listingId,
                        "MemberMlsId": request.memberMlsId,
                        "ListingAddressHint": request.address
                    }
                },
                res => {
                    var pkg = res.data;
                    pkg.isNew = res.status == 201;
                    that.cache.update(pkg);
                    that.handleCallbacks(pkg, request.onSuccess, request.onComplete);
                },
                err => that.errorHandler(err, request.onError, request.onComplete)
            );
        }

        that.postPackage = function (session, listingId, onSuccess, onError, onComplete) {
            return httpWithCancel.http(
                {
                    method: 'POST',
                    url: `./api/v1/MediaPackages?$Expand=Listing,Photos,Tours`,
                    data: {
                        "ListingId": listingId
                    }
                },
                res => {
                    var pkg = res.data;
                    pkg.isNew = res.status == 201;
                    that.cache.update(pkg);
                    that.handleCallbacks(pkg, onSuccess, onComplete);
                },
                err => that.errorHandler(err, onError, onComplete)
            );
        }

        that.postPackageWoListing = function (session, address, memberMlsId, onSuccess, onError, onComplete) {
            return httpWithCancel.http(
                {
                    method: 'POST',
                    url: `./api/v1/MediaPackages?$Expand=Listing,Photos,Tours`,
                    data: {
                        "MemberMlsId": memberMlsId,
                        "ListingAddressHint": address
                    }
                },
                res => {
                    that.cache.update(res.data);
                    that.handleCallbacks(res.data, onSuccess, onComplete);
                },
                err => that.errorHandler(err, onError)
            );
        }

        that.updatePackage = function (request) {
            return httpWithCancel.http(
                {
                    method: 'PATCH',
                    url: `./api/v1/MediaPackages(${request.package.MediaPackageId})?$Expand=Listing,Photos,Tours`,
                    data: request.package
                },
                res => {
                    that.cache.update(res.data);
                    that.handleCallbacks(res.data, request.onSuccess, request.onComplete);
                },
                err => that.errorHandler(err, request.onError)
            );
        }

        that.deletePackage = function (request) {
            var args = {
                method: 'DELETE',
                url: `./api/v1/MediaPackages(${request.packageId})?suppressEmailNotification=${!request.sendNotifcation}`
            };

            if (request.reason)
                args['data'] = request.reason;

            return httpWithCancel.http(
                args,
                res => {
                    that.cache.remove(res.data);
                    that.handleCallbacks(res.data, request.onSuccess, request.onComplete);
                },
                err => that.errorHandler(err, request.onError, request.onComplete)
            );
        }

        that.importMlsPhotos = function (request) {
            return httpWithCancel.http(
                {
                    method: 'POST',
                    url: `./api/v1/ImportMlsPhotos(${request.package.MediaPackageId})?$Expand=Listing,Photos,Tours`,
                    data: request.package
                },
                res => {
                    that.cache.update(res.data);
                    that.handleCallbacks(res.data, request.onSuccess, request.onComplete);
                },
                err => that.errorHandler(err, request.onError)
            );
        }

        that.packageDelta = function (request) {
            if (!request.package || !request.package.ListingId)
                return;

            fetch(`/api/v1/PackageDelta(${request.package.MediaPackageId})`)
                .then(response => response.json())
                .then(data => {
                    that.callback(data, request.onSuccess);
                    that.callback(data, request.onComplete);
                })
                .catch(err => {
                    that.callback(err, request.onError);
                    that.callback(err, request.onComplete);
                });
        }

        that.notify = function (packageId, suppressEmail, success, error) {

            var args = {
                url: `./api/v1/MediaPackages(${packageId})/MarkReady`,
                method: 'POST'
            };

            if (suppressEmail)
                args.url += '?suppressEmailNotification=true';

            return httpWithCancel.http(args, success, error);
        }

        that.contactCreator = function (session, packageId, message, success, error) {

            var args = {
                url: `./api/v1/MediaPackages(${packageId})/ContactCreator`,
                method: 'POST',
                data: '=' + message
            };

            args.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

            return httpWithCancel.http(args, success, error);
        }

        that.feedback = function (session, message, success, error) {

            var args = {
                url: `./api/v1/MediaPackages/Feedback`,
                method: 'POST',
                data: '=' + message
            };

            args.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

            return httpWithCancel.http(args, success, error);
        }

        that.approve = function (session, packageId, success, error) {
            var args = {
                url: `./api/v1/MediaPackages(${packageId})/Publish`,
                method: 'POST'
            };

            return httpWithCancel.http(args, success, error);
        }

        that.cancelPublish = function (packageId, success, error) {
            var args = {
                url: `./api/v1/MediaPackages(${packageId})/CancelPublish`,
                method: 'POST'
            };

            return httpWithCancel.http(args, success, error);
        }

        that.getHistory = function (packageId, onSucess, onError) {

            return httpWithCancel.http(
                {
                    method: 'GET',
                    url: `./api/v1/MediaPackages(${packageId})/History`
                },
                onSucess,
                error => { that.errorHandler(error, onError) }
            );
        }
    }]);