app.service('imgHelperService',
    ['$rootScope', 'growl', 'photoService', 'sessions', 'configService',
    function ($rootScope, growl, photoService, sessions, configService) {

        var that = this;
        that.validMimeTypes = 'image/jpeg';
        configService.getConfig(function (response) {
            that.config = response.data;
        }, function (response) {
            console.log('test');
        });

        that.isTypeValid = function (file) {
            if ((that.validMimeTypes === (void 0) || that.validMimeTypes === '') || that.validMimeTypes.indexOf(file.type) > -1) {
                return true;
            } else {
                growl.error(`Only JPEG photos are accepted`); //File must be one of following types: ${that.validMimeTypes}`);
                return false;
            }
        };

        that.queueFileUpload = function (files, session, scope) {
            var uploadQueue = []; // Queue to manage uploads
            var activeUploads = 0; // Number of active uploads
            var startTime, endTime; // Variables to track time
            var filesToUpload = files.length; // Total number of files to upload

            function startTimer() {
                if (startTime === undefined) {
                    startTime = new Date();
                }
            }

            function endTimer() {
                if (--filesToUpload === 0) {
                    endTime = new Date();
                    var totalTime = (endTime - startTime) / 1000; // Convert to seconds
                    var minutes = Math.floor(totalTime / 60);
                    var seconds = Math.round(totalTime % 60);
                    console.log("Total upload time: " + minutes + " minutes and " + seconds + " seconds");
                }
            }

            function enqueueUpload(photo, file) {
                if (activeUploads < that.config.maxConcurrentUploads) {
                    uploadFile(photo, file);
                } else {
                    uploadQueue.push({ photo, file });
                }
            }

            function uploadFile(photo, file) {
                startTimer(); // Start the timer when the first upload starts

                var success = function (response) {
                    var photoIndex = scope.package.Photos.indexOf(photo);
                    scope.package.Photos[photoIndex] = response.data;
                    uploadNext();
                    endTimer(); // End the timer when a file upload completes
                };
                var error = function (response) {
                    var photoIndex = scope.package.Photos.indexOf(photo);
                    scope.package.Photos.splice(photoIndex, 1);
                    sessions.reportHttpError(response);
                    uploadNext();
                    endTimer(); // End the timer when a file upload completes
                };

                photoService.postPhoto(session, scope.package.MediaPackageId, photo, file, success, error);
                activeUploads++;
            }

            function uploadNext() {
                activeUploads--;
                if (uploadQueue.length > 0 && activeUploads < that.config.maxConcurrentUploads) {
                    var nextUpload = uploadQueue.shift();
                    uploadFile(nextUpload.photo, nextUpload.file);
                }
            }

            for (var i = 0; i < files.length; i++) {
                var file = files[i];

                if (!that.alreadyAdded(file, scope.package.Photos) && that.isTypeValid(file)) {
                    var photo = new PtuPhoto(null, file.type, file.name, file.size, null, scope.package.Photos.length);
                    photo.state = photoStateEnum.uploading;
                    scope.package.Photos.push(photo);

                    enqueueUpload(photo, file);
                }
            }
        }

        that.alreadyAdded = function (file, photos) {
            var preExisting = photos.find(x => file.name.includes(`thumbnail-${x.MediaKey}`));
            return preExisting !== null && preExisting !== undefined;
        }

        that.atMaxPhotos = function (photos) {
            const hardLimit = that.config ? that.config['hardLimit'] : $rootScope.ptuConfig['hardLimit'],
                atPhotoLimit = photos?.length >= hardLimit;

            if (atPhotoLimit)
                growl.error(`The limit of ${hardLimit} photos has been reached`);
            return atPhotoLimit;
        }
    }]
);

app.directive('fileDropZone', ['imgHelperService', 'sessions', function (imgHelperService, sessions) {
    return {
        restrict: 'A',
        scope: {
            package: "=",
            photos: "="
        },
        link: function (scope, element, attrs) {
            var checkSize,
                isTypeValid,
                processDragOverOrEnter,
                validMimeTypes;

            processDragOverOrEnter = function (event) {
                if (event != null) {
                    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ||
                        navigator.userAgent.toLowerCase().indexOf('msie') > -1) {
                        // Firefox requires a getData call before preventDefault() is called 
                        // to maintain the dataTrasfer Object.  This call is done argument free
                        // which will generate a console error, but allowes the dt object to be accessile
                        event.dataTransfer.getData();
                    }
                    event.dataTransfer.effectAllowed = 'copy';
                    event.preventDefault();
                }

                return false;
            };

            element.bind('dragover', processDragOverOrEnter);
            element.bind('dragenter', processDragOverOrEnter);

            return element.bind('drop', function (event) {

                var files = event.dataTransfer.files,
                    session = sessions.getCurrent();

                if (event != null) {
                    event.preventDefault();
                }

                imgHelperService.queueFileUpload(files, session, scope);

                return false;
            });
        }
    };
}]);

app.directive('fileRead', ['imgHelperService', 'sessions', function (imgHelperService, sessions) {
    return {
        restrict: 'A',
        scope: {
            package: "=",
            photos: "="
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var files = changeEvent.target.files,
                    session = sessions.getCurrent();

                imgHelperService.queueFileUpload(files, session, scope);

            });
        }
    }
}]);
