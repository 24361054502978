app.component('ptuNavBar', {
    bindings: { },
    templateUrl: './App/NavBar/NavBar.html',
    controller: ['configService', 'sessions', '$location', '$http', '$scope', '$rootScope', function (configService, sessions, $location, $http, $scope, $rootScope) {

        var that = this;
        that.showAccountSettings = false;

        that.init = function () {
            that.username = null;
            that.fullname = null;
            that.openLogoutModal = false;
            that.logoutProcessing = false;
            that.showNavMenu = false;
            that.showEnv = false;

            configService.getConfig(
                function (response) {
                    that.ptuConfig = response.data;
                    let env = that.ptuConfig.envLable ?? '';
                    that.showEnv = env.toLowerCase().indexOf('prod') < 0;
                },
                function (response) { console.log('Error retrieving config values'); }
            );

            that.session = sessions.getCurrent();
            if (that.session) {
                that.username = that.session.username;
                that.showAccountSettings = that.session.isVendor;                
            }

            that.hideBrand = $location.url().startsWith('/password-reset');
        }

        that.showPublishPermissions = function () {
            return that.ptuConfig && that.ptuConfig.autoPublishEnabled
                && that.session && !that.session.isVendor;
        }
        
        that.closeAllNavDropdowns = function () {
            const navDropdowns = Array.prototype.slice.call(document.querySelectorAll('.navbar-menu, .navbar-dropdown, .navbar-burger'), 0);
            navDropdowns.forEach(el => el.classList.remove('is-active'));
        }

        that.closeAllNavDropdownsByKey = function (evt) {
            const e = evt || window.event;
            if (e.key === 'Esc' || e.key === 'Escape')
                that.closeAllNavDropdowns();
        }

        that.toggleNavMenu = function () {
            that.showNavMenu = !that.showNavMenu;

            if (that.showNavMenu) {
                //document.removeEventListener('click', that.closeAllNavDropdowns);
                //document.addEventListener('click', that.closeAllNavDropdowns);
                document.removeEventListener('keydown', that.closeAllNavDropdownsByKey);
                document.addEventListener('keydown', that.closeAllNavDropdownsByKey);
            }

            return that.showNavMenu;
        }

        that.confirmLogout = function () {
            that.openLogoutModal = true;
            that.logoutProcessing = false;
        }

        that.logout = function () {
            that.logoutProcessing = true;
            sessions.end();
            that.username = null;
            that.openLogoutModal = false;
            that.closeAllNavDropdowns();
            that.logoutProcessing = false;
            $location.url('/Login');
        }

        $rootScope.$on('loggedIn', function (evt, session) {
            that.username = session.username;
        });

        $rootScope.$on('loggedOut', function (evt, session) {
            that.username = null;
        });

        that.showFeedback = false;
        that.isProcessingShowFeedback = false;
        that.feedbackMessage = '';
        that.sendFeedback = function () {
            that.isProcessingShowFeedback = true;
            $http.post(`./api/v1/MediaPackages/Feedback`, {
                Username: that.username,
                Url: window.location.href,
                Feedback: that.feedbackMessage
            }).then(() => { //todo: auto close?
                that.isProcessingShowFeedback = false;
                that.showFeedback = false;
                that.feedbackMessage = '';
            });
        };

        //ensures that session gets reloaded on log out / log in.
        $scope.$on('$routeChangeStart', function ($event, next, current) {
            that.init();
        });

        //Admin Impersonate Feature
        that.impersonateModal = false;
        that.isProcessingImpersonate = false;
        that.impersonateName = '';
        that.showImpersonate = function () {
            return that.ptuConfig && that.session && that.session.isAdmin && !that.session.isImpersonated;
        }
        that.impersonate = function () {
            let success = function () {
                that.isProcessingImpersonate = false;
                that.impersonateModal = false;
                that.impersonateName = '';
                that.session = sessions.getCurrent();
                that.username = that.session.username;
                $location.url('/login'); //redirecting to login will force a content refresh on packages page
            },
            error = function () { 
                that.isProcessingImpersonate = false;
            };

            that.isProcessingImpersonate = true;
            sessions.impersonateUser(that.impersonateName, success, error);
        }

        that.isProcessingUnpersonate = false;
        that.unpersonate = function () {
            let success = function () {
                that.isProcessingUnpersonate = false;
                that.session = sessions.getCurrent();
                that.username = that.session.username;
                $location.url('/login'); //redirecting to login will force a content refresh on packages page
            },
                error = function () {
                    that.isProcessingUnpersonate = false;
                };

            that.isProcessingUnpersonate = true;
            sessions.unpersonate(success, error);
        }

        that.init();
    }]
});