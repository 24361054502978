app.service('photoService', ['httpWithCancel', 'sessions', '$http', '$q', function (httpWithCancel, sessions, $http, $q) {

    var that = this;

    that.postPhoto = function (session, packageId, photo, file, success, error, doReorder) {
        var url = `./api/v1/MediaPackages(${packageId})/Photos`,
            fd = new FormData(),
            defer = $q.defer(),
            p = photo;

        fd.append("Content", file);
        fd.append("LongDescription", p.LongDescription || "");
        fd.append("Order", p.Order);
        //fd.append("MarkedForRotation", doReorder ? -1 : p.RotateInstructions); // TODO: not black magic

        p.downloadPercent = 0;

        $http.post(url, fd, {
            headers: {
                'Content-Type': undefined,
                'SessionId': session.id,
                'FromUI': true
            },
            uploadEventHandlers: {
                progress: function (e) {
                    var percent = e.loaded * 100 / e.total;
                    p.downloadPercent = (percent === 0) ? 0 : Math.round(percent);
                    defer.notify(p.downloadPercent);
                }
            }
        }).then(success, error);
    }

    that.patchPhoto = function (session, packageId, mediaKey, propertyName, value, success, error) {
        var url = `./api/v1/MediaPackages(${packageId})/Photos(${mediaKey})`,
            data = {};

        data[propertyName] = value;

        $http.patch(url, data, {
            headers: { 'DisableAutoResort': true }
        }).then(success, error);
    }
                               
    that.deletePhoto = function (session, packageId, mediaKey, success, error) {
        var url = `./api/v1/MediaPackages(${packageId})/Photos`;

        $http.delete(url, {
            headers: {
                'DisableAutoResort': true,
                'Content-Type': 'text/plain'
            },
            data: mediaKey
        }).then(success, error);
    }

    that.getPublishedPhotos = function (session, listingId, success, error) {

        var args = {                
            url: `./api/odata/listings(${listingId})/publishedphotos`,
            method: 'GET'
        };

        return httpWithCancel.http(args, success, error);
    };


}]);