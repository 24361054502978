app.component('listingSearch', {
    bindings: {
        hidePhotoCount: '<',
        hideVtCount: '<',
        hideOffice: '<'
    },
    templateUrl: './App/Listings/ListingSearchResults.html',
    controller: ['$location', 'listings', 'sessions', 'packageService',
        function ($location, listings, sessions, packageService) {

        var that = this;

        that.init = function () {
            that.searchMode = true;
            that.results = null;
            that.nextListingLink = null;
            that.sortValue = 'PhotoExpirationInDays';
            that.session = sessions.getCurrent();

            if (that.session.listings !== null && that.session.listings.length > 0) {
                that.results = that.session.listings;
            }
            //else {
            //    that.results = null;
            that.newSearch();
            //}
        };

        that.listingsFound = function (value) {
            that.session.listings = value;
            sessions.update(that.session);
            that.results = value;
            that.searchMode = false;
        };

        that.resort = function (field) {

            if (field === that.sortValue) {
                that.sortValue = '-' + field;
            } else {
                that.sortValue = field;
            }
        }

        that.loadMoreResults = function () {
            var session = sessions.getCurrent(),
            success = function (response) {
                that.isSearching = false;
                that.cancelFunc = null

                if (response === null || response.data === null) {
                        sessions.reportError('There was an internal error');
                } else {
                    that.results = that.results.concat(response.data.value);
                    var session = sessions.getCurrent();
                    session.listings = that.results;
                    sessions.update(session);

                    if (response.data.hasOwnProperty("@odata.nextLink")) {
                        that.nextListingLink = response.data["@odata.nextLink"];
                    } else {
                        that.nextListingLink = null;
                    }
                }
            },
            error = function (response) {
                sessions.reportHttpError(response);
            };

            that.isSearching = true;
            that.cancelFunc = listings.getMoreListings(session, that.nextListingLink, success, error);
        };

        that.listingSelected = function (listing) {
            var selectedListing = listing;
            selectedListing.isProcessing = true;
            that.disableSelect = true;
            packageService.createPackage({
                listingId: listing.ListingId,
                onSuccess: _ => {
                    $location.url(`/packages/${package.MediaPackageId}`);
                },
                onComplete: _ => {
                    that.disableSelect = false;
                    selectedListing.isProcessing = false;
                }
            });
        };

        that.newSearch = function () {
            that.search = listings.newSearch();
            that.openSearchModal = true;
        }

        that.close = function () {
            $location.url('/packages');
        }

        that.init();
    }]
});