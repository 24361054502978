app.service('httpWithCancel', ['$http', '$q', function ($http, $q) {

    var that = this;

    that.http = function (args, success, error) {

        var defer = $q.defer(),
            cancelFunc = function () {
                defer.resolve();
            },
            errorWrapper = function (response) {
                //only use the error callback when the error is not a canceled request
                if (response.status !== -1) {
                    error(response);
                }
            };

        args.timeout = defer.promise;

        $http(args).then(success, errorWrapper);

        return cancelFunc;
    };

}]);