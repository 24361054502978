app.service('errorMessageService', [function () {

    var that = this;

    that.formatError = function (httpResponse) {
        var errorMessage = `ERROR: ${httpResponse.status}`;

        if (httpResponse.statusText && httpResponse.statusText !== "") {
            errorMessage += ` - ${httpResponse.statusText}`
        }

        if (httpResponse.data !== null && httpResponse.data !== undefined && httpResponse.data !== '' && httpResponse.data.length < that.extendedErrorMessageMax) {
            errorMessage += ` - ${httpResponse.data}`;
        }

        return errorMessage;
    }
}]);