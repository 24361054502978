app.service('tourService', ['httpWithCancel', 'sessions', '$http', function (httpWithCancel, sessions, $http) {

    var that = this;

    that.post = function (session, packageId, mediaUrl, order, success, error) {

        var url = `./api/v1/MediaPackages(${packageId})/Tours`,
            data = {
                'MediaURL': mediaUrl,
                'Order': order
            },
            args = {};

        $http.post(url, data, args).then(success, error);
    }

    that.patch = function (session, mediaKey, packageId, mediaUrl, order, success, error) {

        var url = `./api/v1/MediaPackages(${packageId})/Tours(${mediaKey})`,
            data = {
                'MediaURL': mediaUrl,
                'Order': order
            },
            args = {};

        $http.patch(url, data, args).then(success, error);
    }

    that.delete = function (session, mediaKey, packageId, success, error) {

        var url = `./api/v1/MediaPackages(${packageId})/Tours(${mediaKey})`,
            args = {};

        $http.delete(url, args).then(success, error);
    }
}]);