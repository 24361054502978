app.component('packageCreate', {
    bindings: {
        show: '=',
        packages: '<'
    },
    templateUrl: './App/Packages/PackageCreate.html',
    controller: ['$scope', 'sessions', 'growl', '$location', 'packageService',
        function ($scope, sessions, growl, $location, packageService) {
            var that = this;

            that.init = function () {
                that.session = sessions.getCurrent();
                that.mode = 'agent-search';
                that.confirmCreateIsOpen = false;
                that.confirmCreateMessage = false;
                that.confirmCreateIsProcessing = false;
                that.isCreatingPackage = false;
                that.importExistingPhotos = false;
                that.showAddressOnlyOption = false;
            };

            that.back = function () {
                switch (that.mode) {
                    case 'listing-search':
                        that.mode = 'agent-search';
                        break;
                    case 'confirm':
                        that.mode = 'listing-search';
                        break;
                    case 'agent-search':
                    default:
                        that.show = false;
                        break;
                }
            }

            that.selectAgent = function (agent) {
                that.selectedAgent = agent;
                that.mode = 'listing-search';
            }

            that.searchFinished = function () {
                that.showAddressOnlyOption = true;
            }

            that.createPackage = function () {
                that.isCreatingPackage = true;

                packageService.createPackage({
                    listingId: that.selectedListing?.ListingId,
                    memberMlsId: that.selectedAgent?.MemberMlsId,
                    address: that.listingAddress,
                    //importExistingPhotos: that.importExistingPhotos,
                    onSuccess: pkg => {
                        if (that.importExistingPhotos) {
                            if (!pkg.isNew) {
                                growl.success('Package already exists. Photos not imported', { ttl: 10000 });
                                that.createFinished(pkg.MediaPackageId);
                            } else {
                                packageService.importMlsPhotos({
                                    package: pkg,
                                    onSuccess: importPkg => {
                                        growl.success(`Package created, ${importPkg.Photos.length} MLS photos imported`, { ttl: 10000 });
                                        that.createFinished(importPkg.MediaPackageId);
                                    }
                                });
                            }
                        } else {
                            that.createFinished(pkg.MediaPackageId);
                            growl.success(pkg.isNew ? 'Package created' : 'Package already exists');
                        }
                    },
                    onError: _ => {
                        that.createFinished();
                    }
                });
            }

            that.createFinished = function (navigateToPackageId) {
                that.isCreatingPackage = false;
                that.confirmCreateIsOpen = false;
                that.confirmCreateIsProcessing = false;
                if (that.selectedListing)
                    that.selectedListing.isProcessing = false;

                if (navigateToPackageId) {
                    $scope.$emit('packageUpdated');
                    that.viewPackage(navigateToPackageId);
                }
            };

            that.viewPackage = function (packageId) {
                $location.url(`/packages/${packageId}`);
            }

            that.confirmCreatePackage = function (listing) {
                that.selectedListing = listing;
                that.mode = 'confirm';
            }

            that.onPackageCreateSuccess = function (response) {
                var package = response.data;
                that.session = sessions.updatePackage(package);
                that.isCreatingPackage = false;
                $location.url(`/packages/${package.MediaPackageId}`);
            }

            that.init();
        }]
});